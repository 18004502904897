import { EquipmentWidget } from '../../visualizer-view-shared/widget-panel/EquipmentWidget';
import { useMoveRobotViewContext } from '../shared';

export function MoveRobotEquipmentWidget() {
  const {
    controlMode,
    isVizbot,
    robot,
    setGripperCommand,
    endEffectorDefaultCommand,
  } = useMoveRobotViewContext();

  return (
    <EquipmentWidget
      robot={robot}
      isVizbot={isVizbot}
      activeWidget={controlMode}
      onCommandChange={setGripperCommand}
      endEffectorDefaultCommand={endEffectorDefaultCommand}
    />
  );
}
