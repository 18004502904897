import { FrameOfReferenceSelector } from './FrameOfReferenceSelector';
import { JogModeButton } from './JogModeButton';
import { TCPSelector } from './TCPSelector';

export function JogParams() {
  return (
    <div className="tw-flex tw-gap-8 tw-m-16">
      <FrameOfReferenceSelector />
      <TCPSelector />
      <JogModeButton />
    </div>
  );
}
