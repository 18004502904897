import { NavigationBar, NavigationBarBackButton } from '@sb/design-system';

import { useAlohaStore } from '../shared/store';

import { MiniArmStatus } from './MiniArmStatus';
import { VisualizerOptions } from './VisualizerOptions';
import { ZeroLeader } from './ZeroLeader';

export function MiniArmControlPanel() {
  const setWidgetMode = useAlohaStore((s) => s.setWidgetMode);

  return (
    <>
      <NavigationBar
        contentLeft={
          <NavigationBarBackButton onClick={() => setWidgetMode('teleop')} />
        }
        className="tw-pl-8 tw-pr-8"
      >
        Mini arm
      </NavigationBar>

      <div className="tw-flex-1 tw-flex tw-flex-col tw-overflow-auto tw-px-16 tw-pb-16 tw-gap-16">
        <ZeroLeader />
        <MiniArmStatus />
        <VisualizerOptions />
      </div>
    </>
  );
}
