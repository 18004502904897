import type { ButtonProps } from '@sb/design-system';
import { Button } from '@sb/design-system';
import type {
  ArmJointLimits,
  ArmJointPositions,
  JointNumber,
} from '@sb/motion-planning';
import {
  useFeatureFlag,
  useGuidedMode,
  useRobotStateKind,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { useMoveRobotViewContext } from '../../shared';
import {
  getIncrementalAngleDegrees,
  getIncrementalAngleRadians,
} from '../jog-params/jogMode';
import { useJogParamsStore } from '../jog-params/store';

interface JointControlJogIncrementalButtonProps extends ButtonProps {
  jointNumber: JointNumber;
  direction: 1 | -1;
  jointAngle?: number;
  limits?: ArmJointLimits[0];
}

export function JointControlJogIncrementalButton({
  jointNumber,
  direction,
  jointAngle,
  limits,
  ...rest
}: JointControlJogIncrementalButtonProps) {
  const { isVizbot, robot } = useMoveRobotViewContext();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const routineRunnerStateKind = useRobotStateKind({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const { runAdHocCommand } = useGuidedMode({ isVizbot });

  const jogIncrementalAngle = useJogParamsStore((s) => s.jogIncrementalAngle);

  const targetAngle =
    jointAngle === undefined
      ? undefined
      : jointAngle +
        getIncrementalAngleDegrees(jogIncrementalAngle) * direction;

  const isDisabled =
    routineRunnerStateKind !== 'Idle' ||
    targetAngle === undefined ||
    limits === undefined ||
    targetAngle <= limits.min ||
    targetAngle >= limits.max;

  const handleClick = async () => {
    if (isDisabled) {
      return;
    }

    const currentJointAngles =
      routineRunnerHandle.getState()?.kinematicState.jointAngles;

    if (!currentJointAngles) {
      return;
    }

    const targetJointAngles = [...currentJointAngles] as ArmJointPositions;

    targetJointAngles[jointNumber] +=
      getIncrementalAngleRadians(jogIncrementalAngle) * direction;

    await runAdHocCommand({
      onRunCommand: async (): Promise<void> => {
        const speedProfile = await getAdHocSpeedProfile(
          robot.id,
          isVizbot,
          isAdHocFullSpeed,
        );

        await routineRunnerHandle.moveToJointSpacePoint(
          targetJointAngles,
          speedProfile,
        );
      },
    });
  };

  return <Button onClick={handleClick} disabled={isDisabled} {...rest} />;
}
