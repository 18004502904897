import { useFeatureFlag } from '@sbrc/hooks';

import { JOG_MODES } from './jogMode';
import { JogParamsButton } from './JogParamsButton';
import { useJogParamsStore } from './store';

export function JogModeButton() {
  const isIncrementalJoggingEnabled = useFeatureFlag('incrementalJogging');

  const [jogMode, setJogSettings] = useJogParamsStore((s) => [
    s.jogMode,
    s.setJogSettings,
  ]);

  if (!isIncrementalJoggingEnabled) {
    return null;
  }

  const { iconKind, name } =
    JOG_MODES.find((s) => s.jogMode === jogMode) ?? JOG_MODES[0];

  return (
    <JogParamsButton
      iconKind={iconKind}
      label1="Mode"
      label2={name}
      onClick={() => setJogSettings({ isJogModeSettingsView: true })}
    />
  );
}
