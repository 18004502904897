import { shallow } from 'zustand/shallow';

import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';

import { MiniArmControlPanel } from './mini-arm/MiniArmControlPanel';
import { useMiniArmVisualizer } from './mini-arm/useMiniArmVisualizer';
import { RecorderSkillEpisodes } from './recorder/RecorderSkillDetail';
import { RecorderSkillsList } from './recorder/RecorderSkillsList';
import { useAlohaStore, useStartAlohaClient } from './shared/store';
import { TeleopControlPanel } from './teleop/TeleopControlPanel';

interface AlohaControlWidgetProps {
  isActive: boolean;
}

export function AlohaControlWidget({ isActive }: AlohaControlWidgetProps) {
  const [widgetMode, selectedSkill] = useAlohaStore(
    (s) => [s.widgetMode, s.selectedSkill] as const,
    shallow,
  );

  useStartAlohaClient(isActive);

  useMiniArmVisualizer();

  if (!isActive) {
    return null;
  }

  return (
    <WidgetView isFullHeight>
      {widgetMode === 'teleop' && <TeleopControlPanel />}

      {widgetMode === 'miniArm' && <MiniArmControlPanel />}

      {widgetMode === 'recorder' && selectedSkill === null && (
        <RecorderSkillsList />
      )}

      {widgetMode === 'recorder' && selectedSkill !== null && (
        <RecorderSkillEpisodes />
      )}
    </WidgetView>
  );
}
