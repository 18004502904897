import { findTCPOffsetOptionOrDefault } from './findTCPOffsetOptionOrDefault';
import { useActiveTCPOffsetOption } from './useActiveTCPOffsetOption';
import { useTCPOffsetOptions } from './useTCPOffsetOptions';

interface ActiveTCPOffsetOptionProps {
  className?: string;
  isVizbot: boolean;
}

export function ActiveTCPOffsetOption({
  className,
  isVizbot,
}: ActiveTCPOffsetOptionProps) {
  const tcpOffsetOption = useActiveTCPOffsetOption({ isVizbot });
  const offsetOptions = useTCPOffsetOptions();

  return (
    <div className={className}>
      {findTCPOffsetOptionOrDefault(tcpOffsetOption, offsetOptions).name}
    </div>
  );
}
