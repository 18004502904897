import { shallow } from 'zustand/shallow';

import {
  Button,
  HoldableButton,
  NavigationBar,
  useInterval,
} from '@sb/design-system';
import { useFeatureFlag, useROSControl } from '@sbrc/hooks';

import { useAlohaStore } from '../shared/store';
import { WidgetModeToggleButton } from '../shared/WidgetModeToggleButton';

import { TeleopBadge } from './TeleopBadge';
import { TeleopNavigationBarMenu } from './TeleopNavigationBarMenu';
import type { TeleopMessageOut } from './types';

export function TeleopControlPanel() {
  const setROSControlEnabled = useROSControl();

  const [client, isConnected, teleopState, isSynced] = useAlohaStore(
    (s) =>
      [s.teleopClient, s.isTeleopConnected, s.teleopState, s.isSynced] as const,
    shallow,
  );

  const isAlohaRecorderEnabled = useFeatureFlag('alohaRecorder');

  const { startInterval, stopInterval } = useInterval(1000);

  const handleHold = (message: TeleopMessageOut) => () => {
    startInterval(() => client?.send(message));
  };

  const handleRelease = () => {
    stopInterval();
    client?.send({ kind: 'stop_movement' });
  };

  return (
    <>
      <NavigationBar
        contentLeft={<TeleopNavigationBarMenu />}
        contentRight={isAlohaRecorderEnabled && <WidgetModeToggleButton />}
        className="tw-pl-8 tw-pr-8"
      >
        Demonstration control
      </NavigationBar>

      <div className="tw-flex-1 tw-flex tw-flex-col tw-overflow-auto tw-gap-8 tw-p-16">
        {teleopState !== 'teleop' && !isSynced && (
          <HoldableButton
            className="tw-rounded-10"
            disabled={!isConnected}
            onHold={handleHold({ kind: 'move_to_sync' })}
            onRelease={handleRelease}
          >
            Hold to sync arms
          </HoldableButton>
        )}

        {teleopState !== 'teleop' && isSynced && (
          <Button
            className="tw-rounded-10"
            disabled={!isConnected || teleopState === 'wait_for_teleop'}
            onClick={() => {
              // TODO - ros2 control will be handled by botman eventually
              setROSControlEnabled(true);
              client?.send({ kind: 'start_teleop' });
            }}
          >
            {teleopState === 'wait_for_teleop'
              ? 'Squeeze gripper to begin'
              : 'Start teleop'}
          </Button>
        )}

        {teleopState === 'teleop' && (
          <Button
            className="tw-rounded-10"
            disabled={!isConnected}
            onClick={() => {
              client?.send({ kind: 'stop_teleop' });
              // TODO - ros2 control will be handled by botman eventually
              setROSControlEnabled(false);
            }}
          >
            Stop teleop
          </Button>
        )}

        {teleopState === 'moving' && (
          <Button
            className="tw-rounded-10"
            disabled={!isConnected}
            onClick={() => client?.send({ kind: 'stop_movement' })}
          >
            Stop
          </Button>
        )}

        {teleopState !== 'teleop' && (
          <HoldableButton
            className="tw-rounded-10"
            disabled={!isConnected}
            onHold={handleHold({ kind: 'move_to_start' })}
            onRelease={handleRelease}
          >
            Hold to move to start
          </HoldableButton>
        )}
      </div>

      <div className="tw-flex tw-justify-between tw-items-end tw-m-16">
        <span className="tw-text-13">{teleopState}</span>
        <TeleopBadge />
      </div>
    </>
  );
}
