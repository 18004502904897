import cx from 'classnames';

import { NavigationBar } from '@sb/design-system';

import { JogParams } from '../jog-params/JogParams';

import { ToolControlJogButton } from './ToolControlJogButton';
import { WidgetModeToggleButton } from './WidgetModeToggleButton';

export function ToolControl() {
  return (
    <>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentRight={<WidgetModeToggleButton />}
      >
        Tooltip
      </NavigationBar>

      <div
        className={cx(
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-grid',
          'tw-grid-cols-[60px_1fr_40px_1fr_60px]',
          'tw-grid-rows-[100px_40px_84px_84px_40px_84px]',
          'tw-items-center',
          'tw-justify-items-center',
          'tw-pt-10',
          'tw-px-16',
        )}
      >
        <div
          className={cx(
            'tw-surface-elevated',
            'tw-shadow-30',
            'tw-w-[136px]',
            'tw-h-[136px]',
            'tw-rounded-full',
            'tw-col-start-3',
            'tw-row-start-2',
          )}
        />
        <div
          className={cx(
            'tw-h-12',
            'tw-w-12',
            'tw-rounded-full',
            'tw-bg-label-tertiary',
            'tw-col-start-3',
            'tw-row-start-2',
          )}
        />

        <div
          className={cx(
            'tw-surface-elevated',
            'tw-shadow-30',
            'tw-w-[136px]',
            'tw-h-[136px]',
            'tw-rounded-full',
            'tw-col-start-3',
            'tw-row-start-5',
          )}
        />
        <div
          className={cx(
            'tw-h-12',
            'tw-w-12',
            'tw-rounded-full',
            'tw-bg-label-tertiary',
            'tw-col-start-3',
            'tw-row-start-5',
          )}
        />

        <ToolControlJogButton dof="x" direction="+" />
        <ToolControlJogButton dof="x" direction="-" />
        <ToolControlJogButton dof="y" direction="+" />
        <ToolControlJogButton dof="y" direction="-" />
        <ToolControlJogButton dof="z" direction="+" />
        <ToolControlJogButton dof="z" direction="-" />
        <ToolControlJogButton dof="roll" direction="+" />
        <ToolControlJogButton dof="roll" direction="-" />
        <ToolControlJogButton dof="pitch" direction="+" />
        <ToolControlJogButton dof="pitch" direction="-" />
        <ToolControlJogButton dof="yaw" direction="+" />
        <ToolControlJogButton dof="yaw" direction="-" />
      </div>

      <JogParams />
    </>
  );
}
