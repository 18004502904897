import { shallow } from 'zustand/shallow';

import {
  Button,
  Icon,
  NavigationBar,
  NavigationBarBackButton,
  SettingsGroup,
  SettingsGroupItem,
} from '@sb/design-system';

import { useAlohaStore } from '../shared/store';
import { WidgetModeToggleButton } from '../shared/WidgetModeToggleButton';

export function RecorderSkillEpisodes() {
  const [
    client,
    isConnected,
    recordState,
    recordings,
    selectedSkill,
    setSelectedSkill,
  ] = useAlohaStore(
    (s) =>
      [
        s.recorderClient,
        s.isRecorderConnected,
        s.recordState,
        s.recordings,
        s.selectedSkill,
        s.setSelectedSkill,
      ] as const,
    shallow,
  );

  if (!selectedSkill) {
    return null;
  }

  const episodes = recordings[selectedSkill] ?? [];

  const getNewEpisodeName = (num = episodes.length + 1): string => {
    const candidateName = `Demonstration ${num}`;

    if (!episodes.includes(candidateName)) {
      return candidateName;
    }

    return getNewEpisodeName(num + 1);
  };

  return (
    <>
      <NavigationBar
        contentLeft={
          <NavigationBarBackButton onClick={() => setSelectedSkill(null)}>
            Skills
          </NavigationBarBackButton>
        }
        contentRight={<WidgetModeToggleButton />}
        className="tw-pl-8 tw-pr-8"
      />

      <h5 className="tw-heading-60 tw-pl-24 tw-px-16">
        <span className="tw-block tw-text-ellipsis">{selectedSkill}</span>
      </h5>

      <section className="tw-flex tw-flex-col tw-overflow-auto tw-px-16">
        <SettingsGroup>
          {episodes.map((episode) => (
            <SettingsGroupItem key={episode}>{episode}</SettingsGroupItem>
          ))}
        </SettingsGroup>

        <p className="tw-text-13 tw-text-label-tertiary tw-pl-16 tw-pt-8">
          {episodes.length} {episodes.length === 1 ? 'episode' : 'episodes'}{' '}
          recorded.
        </p>
      </section>

      {recordState === 'stopped' && (
        <Button
          className="tw-rounded-6 tw-m-16"
          variant="Filled"
          disabled={!isConnected}
          onClick={() => {
            client?.send({
              kind: 'start_recording',
              metadata: {
                task_name: selectedSkill,
                episode_name: getNewEpisodeName(),
              },
            });
          }}
        >
          <Icon kind="circleInsetFill" />
          <span>Record</span>
        </Button>
      )}

      {recordState === 'recording' && (
        <Button
          className="tw-rounded-6 tw-m-16"
          variant="Filled"
          disabled={!isConnected}
          onClick={() => {
            client?.send({
              kind: 'stop_recording',
            });
          }}
        >
          <Icon kind="stopFill" />
          <span>Stop</span>
        </Button>
      )}
    </>
  );
}
