import { useEffect } from 'react';
import { create } from 'zustand';

import { six } from '@sb/utilities';
import { API_ENDPOINT } from '@sbrc/utils';

import { RecorderMessageIn, RecorderMessageOut } from '../recorder/types';
import { TeleopMessageIn, TeleopMessageOut } from '../teleop/types';

import { AlohaAPIClient } from './AlohaAPIClient';
import type { AlohaState, AlohaWidgetMode } from './types';

interface VisualizerOptions {
  x: number;
  y: number;
  z: number;
  isGhost: boolean;
}

interface AlohaStore extends AlohaState {
  teleopClient: AlohaAPIClient<TeleopMessageOut, TeleopMessageIn> | null;
  recorderClient: AlohaAPIClient<RecorderMessageOut, RecorderMessageIn> | null;
  setWidgetMode: (mode: AlohaWidgetMode) => void;
  selectedSkill: string | null;
  setSelectedSkill: (skill: string | null) => void;
  visualizerOptions: VisualizerOptions;
  setVisualizerOptions: (options: VisualizerOptions) => void;
}

export const useAlohaStore = create<AlohaStore>((set) => {
  return {
    isTeleopConnected: false,
    isRecorderConnected: false,
    widgetMode: 'teleop',
    teleopState: null,
    isSynced: false,
    syncGap: [],
    recordState: null,
    recordings: {},
    managedBotIDs: [],
    miniArmJointPositions: six(0),
    miniArmGripperPosition: 0,
    isMiniArmFrozen: false,
    miniArmJointConnectivity: [],
    teleopClient: null,
    recorderClient: null,
    setWidgetMode: (widgetMode) => set({ widgetMode }),
    selectedSkill: null,
    setSelectedSkill: (selectedSkill) => set({ selectedSkill }),
    visualizerOptions: { x: 1, y: -1.5, z: 0, isGhost: false },
    setVisualizerOptions: (visualizerOptions) => set({ visualizerOptions }),
  };
});

function startTeleopClient() {
  if (useAlohaStore.getState().teleopClient) {
    // alread started
    return;
  }

  const teleopClient = new AlohaAPIClient(
    `${API_ENDPOINT}teleop-bot`,
    TeleopMessageIn,
    TeleopMessageOut,
  );

  useAlohaStore.setState({ teleopClient });

  teleopClient.isConnected$.subscribe((isTeleopConnected) => {
    useAlohaStore.setState({ isTeleopConnected });

    if (isTeleopConnected) {
      // subscribe to state messages
      teleopClient.send({ kind: 'subscribe' });
    }
  });

  teleopClient.receive$.subscribe((message) => {
    switch (message.kind) {
      case 'state_update':
        useAlohaStore.setState({
          teleopState: message.state.teleop_state,
          isSynced: message.state.bots_in_sync,
          syncGap: message.state.sync_gap,
          miniArmJointPositions: message.state.mini_arm_state.joint_positions,
          miniArmGripperPosition: message.state.mini_arm_state.gripper_position,
          isMiniArmFrozen: message.state.mini_arm_state.is_frozen,
          miniArmJointConnectivity:
            message.state.mini_arm_state.joint_connectivity,
        });

        break;
      default:
        break;
    }
  });
}

function startRecorderClient() {
  if (useAlohaStore.getState().recorderClient) {
    // alread started
    return;
  }

  const recorderClient = new AlohaAPIClient(
    `${API_ENDPOINT}recorder-bot`,
    RecorderMessageIn,
    RecorderMessageOut,
  );

  useAlohaStore.setState({ recorderClient });

  recorderClient.isConnected$.subscribe((isRecorderConnected) => {
    useAlohaStore.setState({ isRecorderConnected });

    if (isRecorderConnected) {
      // subscribe to state messages
      recorderClient.send({ kind: 'subscribe' });
    }
  });

  recorderClient.receive$.subscribe((message) => {
    switch (message.kind) {
      case 'record_state_update':
        useAlohaStore.setState({
          recordState: message.state.record_state,
          recordings: message.state.recordingsMetadata,
          managedBotIDs: message.state.managed_bot_ids,
        });

        break;
      default:
        break;
    }
  });
}

export function useStartAlohaClient(isAlohaActive: boolean) {
  const widgetMode = useAlohaStore((s) => s.widgetMode);

  useEffect(() => {
    if (!isAlohaActive) {
      // no action
    } else if (widgetMode === 'recorder') {
      startRecorderClient();
    } else {
      startTeleopClient();
    }
  }, [isAlohaActive, widgetMode]);
}
