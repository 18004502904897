import { SettingsGroup, SettingsGroupItem } from '@sb/design-system';
import { JOINT_NAMES } from '@sbrc/utils';

import { useAlohaStore } from '../shared/store';

import { MiniArmJointStatus } from './MiniArmJointStatus';

const JOINTS = [...JOINT_NAMES, 'Gripper'];

export function MiniArmStatus() {
  const isMiniArmFrozen = useAlohaStore((s) => s.isMiniArmFrozen);

  return (
    <section>
      <h5 className="tw-heading-40 tw-pl-16">Status</h5>

      <SettingsGroup>
        <SettingsGroupItem>
          <span className="tw-flex-1">Status</span>
          {isMiniArmFrozen ? (
            <span className="tw-text-orange">Frozen</span>
          ) : (
            <span className="tw-text-green">Unfrozen</span>
          )}
        </SettingsGroupItem>

        {JOINTS.map((jointName, index) => (
          <MiniArmJointStatus
            key={jointName}
            jointName={jointName}
            jointNumber={index}
          />
        ))}
      </SettingsGroup>
    </section>
  );
}
