import { useCallback, useEffect, useState } from 'react';

import { SegmentedButton } from '@sb/design-system';
import { useRobotStateKind } from '@sbrc/hooks';

import RobotControlToggleAlert from './RobotControlToggleAlert';

interface RobotControlToggleProps {
  isVizbot: boolean;
  isLiveRobotConnected?: boolean;
  isSwitchableOnRunningRoutine?: boolean;
  setIsVizbot: (isVizbot: boolean) => void;
}

export function RobotControlToggle({
  isVizbot,
  isLiveRobotConnected,
  isSwitchableOnRunningRoutine,
  setIsVizbot,
}: RobotControlToggleProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const realRoutineRunnerKind = useRobotStateKind({});

  const isRealRoutineRunning = realRoutineRunnerKind === 'RoutineRunning';

  const vizbotRoutineRunnerKind = useRobotStateKind({ isVizbot: true });

  const isVizbotRoutineRunning = vizbotRoutineRunnerKind === 'RoutineRunning';

  const controlViewRoutineRunnerKind = useRobotStateKind({ isVizbot });

  const isControlViewStateFailure = controlViewRoutineRunnerKind === 'Failure';

  const openModal = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onCloseModalAndSwitchRobotControl = useCallback(() => {
    if (!isVizbotRoutineRunning && !isRealRoutineRunning) {
      setIsModalOpen(false);
    }

    setIsVizbot(!isVizbot);
  }, [isVizbot, isRealRoutineRunning, isVizbotRoutineRunning, setIsVizbot]);

  const handleLiveRobotSwitch = () => {
    if (isVizbotRoutineRunning && !isSwitchableOnRunningRoutine) {
      openModal();
    }

    if (!isVizbotRoutineRunning || isSwitchableOnRunningRoutine) {
      setIsVizbot(false);
    }
  };

  const handleSimulatedRobotSwitch = () => {
    if (isRealRoutineRunning && !isSwitchableOnRunningRoutine) {
      openModal();
    }

    if (!isRealRoutineRunning || isSwitchableOnRunningRoutine) {
      setIsVizbot(true);
    }
  };

  // If the robot goes into failure or loses connection while the modal is open, we
  // should cancel the modal. In both these cases, the robot's state will return to
  // 'Idle' and the user can switch between robot control.
  useEffect(() => {
    if (isControlViewStateFailure || !isLiveRobotConnected) onCloseModal();
  }, [isControlViewStateFailure, isLiveRobotConnected]);

  return (
    <div className="tw-flex">
      <SegmentedButton
        checked={!isVizbot}
        onChange={handleLiveRobotSwitch}
        size={36}
        data-testid="robot-control-toggle-live-robot-button"
      >
        Live robot
      </SegmentedButton>
      <SegmentedButton
        checked={isVizbot}
        onChange={handleSimulatedRobotSwitch}
        size={36}
        data-testid="robot-control-toggle-simulation-button"
      >
        Visualizer
      </SegmentedButton>

      <RobotControlToggleAlert
        isOpen={isModalOpen}
        onCloseAndSwitch={onCloseModalAndSwitchRobotControl}
        isVizbot={isVizbot}
        onClose={onCloseModal}
      />
    </div>
  );
}
