import WidgetView from '../../../visualizer-view-shared/widget-panel/WidgetView';
import { useMoveRobotViewContext } from '../../shared';
import { JogModeSettings } from '../jog-params/JogModeSettings';
import { useJogParamsStore } from '../jog-params/store';

import { ToolControl } from './ToolControl';
import { ToolControlSnapToAxisMode } from './ToolControlSnapToAxisMode';
import { ToolControlTargetMode } from './ToolControlTargetMode';

export function MoveRobotToolControlWidget() {
  const { controlMode, setControlMode } = useMoveRobotViewContext();

  const isJogModeSettingsView = useJogParamsStore(
    (s) => s.isJogModeSettingsView,
  );

  const getContent = () => {
    if (isJogModeSettingsView) {
      return <JogModeSettings />;
    }

    if (controlMode === 'toolControl') {
      return <ToolControl />;
    }

    if (controlMode === 'toolControlTarget') {
      return (
        <ToolControlTargetMode
          onSnapToAxisMode={() => setControlMode('toolControlSnapToAxis')}
        />
      );
    }

    if (controlMode === 'toolControlSnapToAxis') {
      return <ToolControlSnapToAxisMode />;
    }

    return null;
  };

  return <WidgetView isFullHeight>{getContent()}</WidgetView>;
}
