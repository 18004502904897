import { useEffect } from 'react';

import { useVisualizerChildren } from '@sbrc/hooks';

import { useAlohaStore } from '../shared/store';

import { MiniArmVisualizer } from './MiniArmVisualizer';

const CHILD_KEY = 'miniArm';

export function useMiniArmVisualizer() {
  const isStarted = useAlohaStore((s) => s.teleopClient !== null);

  const { setVisualizerChild } = useVisualizerChildren();

  useEffect(() => {
    if (!isStarted) {
      return undefined;
    }

    setVisualizerChild(CHILD_KEY, <MiniArmVisualizer />);

    // remove visualization on unmount
    return () => {
      setVisualizerChild(CHILD_KEY, null);
    };
  }, [setVisualizerChild, isStarted]);
}
