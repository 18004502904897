import type { IconKind } from '@sb/design-system';
import type { DistanceUnit } from '@sb/remote-control/types';
import type { DistanceUnitInfo } from '@sb/remote-control/util/distance';
import { convertDegreesToRadians } from '@sb/utilities';

export type JogMode = 'continuous' | 'incremental';

export const JOG_MODES: Array<{
  jogMode: JogMode;
  iconKind: IconKind;
  name: string;
}> = [
  { jogMode: 'continuous', iconKind: 'waveContinuous', name: 'Continuous' },
  { jogMode: 'incremental', iconKind: 'waveBroken', name: 'Incremental' },
];

export const JOG_INCREMENTAL_DISTANCE_INFO: Array<
  { id: number } & Record<DistanceUnit, number>
> = [
  { id: 1, meter: 0.002, millimeter: 2, inch: 0.1 },
  { id: 2, meter: 0.005, millimeter: 5, inch: 0.25 },
  { id: 3, meter: 0.01, millimeter: 10, inch: 0.5 },
];

function getIncrementalDistanceInfo(id: number) {
  return (
    JOG_INCREMENTAL_DISTANCE_INFO.find((i) => i.id === id) ??
    JOG_INCREMENTAL_DISTANCE_INFO[0]
  );
}

export function getIncrementalDistanceString(
  id: number,
  distanceUnit: DistanceUnitInfo,
): string {
  return [
    getIncrementalDistanceInfo(id)[distanceUnit.unit],
    distanceUnit.abbreviation,
  ].join(' ');
}

export function getIncrementalDistanceMeters(
  id: number,
  distanceUnit: DistanceUnitInfo,
): number {
  return distanceUnit.toMeters(
    getIncrementalDistanceInfo(id)[distanceUnit.unit],
  );
}

export const JOG_INCREMENTAL_ANGLE_INFO: Array<{
  id: number;
  degrees: number;
}> = [
  { id: 1, degrees: 0.5 },
  { id: 2, degrees: 1 },
  { id: 3, degrees: 2 },
];

export function getIncrementalAngleString(id: number): string {
  const info =
    JOG_INCREMENTAL_ANGLE_INFO.find((i) => i.id === id) ??
    JOG_INCREMENTAL_ANGLE_INFO[0];

  return `${info.degrees}°`;
}

export function getIncrementalAngleDegrees(id: number): number {
  const info =
    JOG_INCREMENTAL_ANGLE_INFO.find((i) => i.id === id) ??
    JOG_INCREMENTAL_ANGLE_INFO[0];

  return info.degrees;
}

export function getIncrementalAngleRadians(id: number): number {
  return convertDegreesToRadians(getIncrementalAngleDegrees(id));
}
