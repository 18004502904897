import cx from 'classnames';

import { Gauge, GaugeContainer, Icon, NavigationBar } from '@sb/design-system';
import { JOINT_NUMBERS } from '@sb/motion-planning';
import {
  useFeatureFlag,
  useRobotJointAnglesDegrees,
  useRobotJointLimitsDegrees,
} from '@sbrc/hooks';
import { JOINT_NAMES } from '@sbrc/utils';

import { useMoveRobotViewContext } from '../../shared';
import { JogParams } from '../jog-params/JogParams';
import { useJogParamsStore } from '../jog-params/store';

import { JointControlJogContinuousButton } from './JointControlJogContinuousButton';
import { JointControlJogIncrementalButton } from './JointControlJogIncrementalButton';
import { WidgetModeToggleButton } from './WidgetModeToggleButton';

export function JointControl() {
  const { isVizbot } = useMoveRobotViewContext();

  const isIncrementalJoggingEnabled = useFeatureFlag('incrementalJogging');

  const jointAnglesDegrees = useRobotJointAnglesDegrees({ isVizbot });

  const jointLimitsDegrees = useRobotJointLimitsDegrees({ isVizbot });

  const jogMode = useJogParamsStore((s) => s.jogMode);

  const ButtonComponent =
    jogMode === 'incremental'
      ? JointControlJogIncrementalButton
      : JointControlJogContinuousButton;

  return (
    <>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentRight={<WidgetModeToggleButton />}
      >
        Joints
      </NavigationBar>

      <div
        className={cx(
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-flex',
          'tw-flex-col',
          'tw-gap-12',
          'tw-pt-16',
          'tw-pb-32',
        )}
      >
        {JOINT_NUMBERS.map((jointNumber) => {
          const jointAngle = jointAnglesDegrees?.[jointNumber];
          const limits = jointLimitsDegrees?.[jointNumber];

          return (
            <div
              key={jointNumber}
              className={cx(
                'tw-flex',
                'tw-mx-16',
                'tw-gap-8',
                'tw-items-center',
              )}
            >
              <span className="tw-w-24">{JOINT_NAMES[jointNumber]}</span>

              <GaugeContainer className="tw-flex-1">
                <ButtonComponent
                  jointNumber={jointNumber}
                  direction={-1}
                  jointAngle={jointAngle}
                  limits={limits}
                  className="tw-rounded-6"
                >
                  <Icon kind="minus" />
                </ButtonComponent>

                <Gauge
                  value={jointAngle ?? 0}
                  min={limits?.min ?? 0}
                  max={limits?.max ?? 0}
                  valueToString={(v) => `${v.toFixed(1)}`}
                />

                <ButtonComponent
                  jointNumber={jointNumber}
                  direction={1}
                  jointAngle={jointAngle}
                  limits={limits}
                  className="tw-rounded-6"
                >
                  <Icon kind="plus" />
                </ButtonComponent>
              </GaugeContainer>
            </div>
          );
        })}
      </div>

      {isIncrementalJoggingEnabled && <JogParams />}
    </>
  );
}
