import { useState } from 'react';

import type { ButtonProps } from '@sb/design-system';
import { HoldableButton } from '@sb/design-system';
import type { CartesianDirection } from '@sb/geometry';
import {
  useFeatureFlag,
  useGuidedMode,
  useRobotStateKind,
  useRobotValidToolDirections,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { useMoveRobotViewContext } from '../../shared';
import { useJogParamsStore } from '../jog-params/store';

import type { DegreeOfFreedom, Direction } from './dofInfo';
import { DOF_INFO } from './dofInfo';

interface ToolControlJogContinuousButtonProps extends ButtonProps {
  dof: DegreeOfFreedom;
  direction: Direction;
}

export function ToolControlJogContinuousButton({
  dof,
  direction,
  ...rest
}: ToolControlJogContinuousButtonProps) {
  const { isVizbot, robot } = useMoveRobotViewContext();

  const routineRunnerStateKind = useRobotStateKind({ isVizbot });

  const frameOfReference = useJogParamsStore((s) => s.frameOfReference);

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const { stopGuidedMode, runAdHocCommand } = useGuidedMode({ isVizbot });

  const validToolDirections = useRobotValidToolDirections({ isVizbot });

  const [isJogging, setIsJogging] = useState(false);

  const dofInfo = DOF_INFO[dof];
  const mappedDof = frameOfReference === 'tooltip' ? dofInfo.baseDOF : dof;

  const cartesianDirection: CartesianDirection = `${direction}${mappedDof}`;

  const isDisabled =
    !isJogging &&
    (routineRunnerStateKind !== 'Idle' ||
      !validToolDirections ||
      !validToolDirections[frameOfReference][cartesianDirection]);

  const startJogging = async () => {
    if (isDisabled) {
      return;
    }

    setIsJogging(true);

    await runAdHocCommand({
      onRunCommand: async () => {
        const speedProfile = await getAdHocSpeedProfile(
          robot.id,
          isVizbot,
          isAdHocFullSpeed,
        );

        const offsetPose = DOF_INFO[mappedDof][direction].offset;

        await routineRunnerHandle.moveToolRelative(
          frameOfReference,
          offsetPose,
          speedProfile,
        );
      },
    });

    setIsJogging(false);
  };

  return (
    <HoldableButton
      onHold={() => startJogging()}
      onRelease={stopGuidedMode}
      disabled={isDisabled}
      {...rest}
    />
  );
}
