import { shallow } from 'zustand/shallow';

import { Button } from '@sb/design-system';

import { useAlohaStore } from '../shared/store';

export function ZeroLeader() {
  const [client, isConnected, isMiniArmFrozen] = useAlohaStore(
    (s) => [s.teleopClient, s.isTeleopConnected, s.isMiniArmFrozen],
    shallow,
  );

  return (
    <div className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">Zero leader</h5>

      <section className="tw-flex tw-flex-col tw-gap-8">
        <Button
          className="tw-rounded-10"
          disabled={!isConnected || !isMiniArmFrozen}
          onClick={() => client?.send({ kind: 'unfreeze' })}
        >
          Unfreeze leader
        </Button>

        <Button
          className="tw-rounded-10"
          disabled={!isConnected || isMiniArmFrozen}
          onClick={() => client?.send({ kind: 'zero_leader' })}
        >
          Zero leader
        </Button>

        <Button
          className="tw-rounded-10"
          disabled={!isConnected || isMiniArmFrozen}
          onClick={() => client?.send({ kind: 'freeze' })}
        >
          Freeze leader
        </Button>

        <Button
          className="tw-rounded-10"
          disabled={!isConnected}
          onClick={() => client?.send({ kind: 'zero_gripper' })}
        >
          Zero gripper (Move gripper to the closed position first)
        </Button>
      </section>
    </div>
  );
}
