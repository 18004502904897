import { Quaternion, Vector3 } from 'three';

import type { CartesianPose } from '@sb/geometry';
import type { FrameOfReference } from '@sb/motion-planning';
import type { DistanceUnitInfo } from '@sb/remote-control/util/distance';

import {
  getIncrementalAngleRadians,
  getIncrementalDistanceMeters,
} from '../jog-params/jogMode';

interface Args {
  tooltipPose: CartesianPose;
  offsetPose: CartesianPose;
  frameOfReference: FrameOfReference;
  distanceUnitInfo: DistanceUnitInfo;
  jogIncrementalDistance: number;
  jogIncrementalAngle: number;
}

export function calculateIncrementalJogTarget({
  tooltipPose,
  offsetPose,
  frameOfReference,
  distanceUnitInfo,
  jogIncrementalDistance,
  jogIncrementalAngle,
}: Args): CartesianPose {
  const tooltipOrientation = new Quaternion(
    tooltipPose.i,
    tooltipPose.j,
    tooltipPose.k,
    tooltipPose.w,
  );

  const targetPose = { ...tooltipPose };

  // translation
  if (offsetPose.w === 1) {
    const distance = getIncrementalDistanceMeters(
      jogIncrementalDistance,
      distanceUnitInfo,
    );

    const targetTranslation = new Vector3(
      offsetPose.x * distance,
      offsetPose.y * distance,
      offsetPose.z * distance,
    );

    if (frameOfReference === 'tooltip') {
      targetTranslation.applyQuaternion(tooltipOrientation);
    }

    targetPose.x += targetTranslation.x;
    targetPose.y += targetTranslation.y;
    targetPose.z += targetTranslation.z;
  }

  // rotation
  if (offsetPose.w !== 1) {
    const angle = getIncrementalAngleRadians(jogIncrementalAngle);

    const qvalue = Math.sin(angle / 2);
    const wvalue = Math.cos(angle / 2);

    const targetOrientation = new Quaternion(
      Math.sign(offsetPose.i) * qvalue,
      Math.sign(offsetPose.j) * qvalue,
      Math.sign(offsetPose.k) * qvalue,
      wvalue,
    );

    if (frameOfReference === 'tooltip') {
      targetOrientation
        .premultiply(tooltipOrientation)
        .multiply(new Quaternion().copy(tooltipOrientation).invert());
    }

    targetOrientation.multiply(tooltipOrientation);

    targetPose.i = targetOrientation.x;
    targetPose.j = targetOrientation.y;
    targetPose.k = targetOrientation.z;
    targetPose.w = targetOrientation.w;
  }

  return targetPose;
}
