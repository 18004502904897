import { useEffect, useMemo, useState } from 'react';
import { Euler, Quaternion, Vector3 } from 'three';
import { shallow } from 'zustand/shallow';

import {
  getPlaneOrientation,
  type CartesianPose,
  type CartesianPosition,
} from '@sb/geometry';
import type { Space } from '@sb/routine-runner';
import { convertDegreesToRadians, wait } from '@sb/utilities';
import {
  useRobotInvertedTCPTransform,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';
import {
  arePosesEqual,
  convertEulerPose,
  convertJointAngles,
} from '@sbrc/utils';

import { useMoveRobotViewContext } from '../../shared';
import { useJogParamsStore, useTargetEulerPose } from '../jog-params/store';

type CalculatorState = 'init' | 'calculatingIK' | 'noSolutions' | 'success';

export function useSnapToAxisCalculator(): CalculatorState {
  const [calculatorState, setCalculatorState] =
    useState<CalculatorState>('init');

  const { routine, isVizbot, setTargetJointAnglesDegrees } =
    useMoveRobotViewContext();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });
  const invertedTCPTransform = useRobotInvertedTCPTransform({ isVizbot });

  const [snapAxis, snapRotation, snapFrame, snapDirection, motionKind] =
    useJogParamsStore(
      (s) =>
        [
          s.snapAxis,
          s.snapRotation,
          s.snapFrame,
          s.snapDirection,
          s.motionKind,
        ] as const,
      shallow,
    );

  const [targetPose, setTargetEulerPose] = useTargetEulerPose(isVizbot);

  const position = useMemo<CartesianPosition>(() => {
    if (
      targetPose?.X === undefined ||
      targetPose?.Y === undefined ||
      targetPose?.Z === undefined
    ) {
      const tooltipPose =
        routineRunnerHandle.getState()?.kinematicState.tooltipPoint;

      if (!tooltipPose) {
        return { x: 0, y: 0, z: 0 };
      }

      return {
        x: tooltipPose.x,
        y: tooltipPose.y,
        z: tooltipPose.z,
      };
    }

    return {
      x: targetPose.X,
      y: targetPose.Y,
      z: targetPose.Z,
    };
  }, [targetPose?.X, targetPose?.Y, targetPose?.Z, routineRunnerHandle]);

  const axisRotation = useMemo<Euler>(() => {
    const rotationRadians = convertDegreesToRadians(snapRotation);

    switch (snapAxis) {
      case 'X':
        if (snapDirection === '+') {
          return new Euler(rotationRadians, 0, 0, 'XYZ');
        }

        return new Euler(Math.PI - rotationRadians, 0, Math.PI, 'ZXY');

      case 'Y':
        if (snapDirection === '+') {
          return new Euler(rotationRadians, 0, Math.PI / 2, 'ZXY');
        }

        return new Euler(Math.PI - rotationRadians, 0, -Math.PI / 2, 'ZXY');

      default:
        if (snapDirection === '+') {
          return new Euler(rotationRadians, -Math.PI / 2, 0, 'YXZ');
        }

        return new Euler(-rotationRadians, Math.PI / 2, 0, 'YXZ');
    }
  }, [snapAxis, snapRotation, snapDirection]);

  const plane = useMemo<Space.Plane | undefined>(
    () =>
      routine?.space.find((s): s is Space.Plane => {
        return s.id === snapFrame && s.kind === 'plane';
      }),
    [routine?.space, snapFrame],
  );

  const frameRotation = useMemo<Quaternion>(() => {
    const quaternion = new Quaternion().setFromEuler(axisRotation);

    if (plane) {
      const planeOrientation = getPlaneOrientation({
        origin: new Vector3(
          plane.positions[0].pose.x,
          plane.positions[0].pose.y,
          plane.positions[0].pose.z,
        ),
        plusX: new Vector3(
          plane.positions[1].pose.x,
          plane.positions[1].pose.y,
          plane.positions[1].pose.z,
        ),
        plusY: new Vector3(
          plane.positions[2].pose.x,
          plane.positions[2].pose.y,
          plane.positions[2].pose.z,
        ),
      });

      quaternion.premultiply(planeOrientation);
    }

    return quaternion;
  }, [axisRotation, plane]);

  const targetCartesianPose = useMemo<CartesianPose | null>(
    () => {
      const target: CartesianPose = {
        ...position,
        w: frameRotation.w,
        i: frameRotation.x,
        j: frameRotation.y,
        k: frameRotation.z,
      };

      if (
        arePosesEqual(
          target,
          routineRunnerHandle.getState()?.kinematicState.tooltipPoint ?? null,
        )
      ) {
        return null;
      }

      return target;
    },
    // recalculate on invertedTCPTransform change, because this means tooltipPoint state will have changed too
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [position, frameRotation, routineRunnerHandle, invertedTCPTransform],
  );

  useEffect(() => {
    if (targetCartesianPose) {
      const euler = convertEulerPose.fromCartesian(targetCartesianPose);
      setTargetEulerPose(euler);
    } else {
      setTargetEulerPose(null);
    }
  }, [targetCartesianPose, setTargetEulerPose]);

  useEffect(() => {
    let cancelled = false;

    const targetKey = isVizbot ? 'vizbot' : 'liveRobot';

    setTargetJointAnglesDegrees((prev) => ({ ...prev, [targetKey]: null }));

    if (!targetCartesianPose) {
      return undefined;
    }

    setCalculatorState('calculatingIK');

    const runIK = async () => {
      // debounce calls to ik
      await wait(500);

      if (cancelled) {
        return;
      }

      const targetJointAngles =
        await routineRunnerHandle.getJointAnglesForCartesianSpacePose(
          targetCartesianPose,
          motionKind,
        );

      if (cancelled) {
        return;
      }

      if (targetJointAngles) {
        const jointAnglesDegrees =
          convertJointAngles.toDegrees(targetJointAngles);

        setTargetJointAnglesDegrees((prev) => ({
          ...prev,
          [targetKey]: jointAnglesDegrees,
        }));

        setCalculatorState('success');
      } else {
        setCalculatorState('noSolutions');
      }
    };

    runIK();

    return () => {
      cancelled = true;
    };
  }, [
    targetCartesianPose,
    motionKind,
    setTargetJointAnglesDegrees,
    isVizbot,
    routineRunnerHandle,
  ]);

  return calculatorState;
}
