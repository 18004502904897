import type { DeviceConfiguration } from '@sb/integrations/device';
import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import type { Equipment, Integrations } from '@sb/remote-control/types';

export function getTCPOffsetOptions(
  equipment: Equipment.ConvertedResponse[] | DeviceConfiguration[],
): Integrations.TCPOffsetOptionsAndNames {
  const options: Integrations.TCPOffsetOptionsAndNames = [
    {
      tcpOffsetOption: 'wrist',
      name: 'Wrist flange',
      iconKind: 'equipmentWristFlange',
    },
  ];

  for (const equipmentItem of equipment) {
    const config =
      'config' in equipmentItem ? equipmentItem.config : equipmentItem;

    const implementation = getEquipmentByKind(config.kind);

    if (implementation.getTCPOffsetOptions) {
      options.push(...implementation.getTCPOffsetOptions(config));
    }
  }

  return options;
}
