import { useEffect, useState } from 'react';

import { useIsRobotConnected } from '@sbrc/hooks';

import { WidgetIconButton } from '../visualizer-view-shared/widget-panel/WidgetIconButton';

import { MoveRobotToolCoordinates } from './MoveRobotToolCoordinates';
import { useMoveRobotViewContext } from './shared';

export function MoveRobotViewPosePopover() {
  const { isCameraView, isVizbot } = useMoveRobotViewContext();

  const isConnected = useIsRobotConnected({ isVizbot });

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(true);

  useEffect(() => {
    if (isCameraView || !isConnected) {
      setIsPopoverOpen(false);
    }
  }, [isCameraView, isConnected]);

  return (
    <>
      {isPopoverOpen && <MoveRobotToolCoordinates />}

      <WidgetIconButton
        activeWidget={isPopoverOpen ? 'pose' : null}
        forWidget="pose"
        iconKind="listBullet"
        label="Pose"
        tooltip="Show the position and orientation of the arm"
        setActiveWidget={(widget) => setIsPopoverOpen(widget === 'pose')}
        disabled={isCameraView || !isConnected}
        isVizbot={isVizbot}
      />
    </>
  );
}
