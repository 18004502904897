import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import type { ComponentColor, IconKind } from '@sb/design-system';
import { Badge, Icon } from '@sb/design-system';

import { useAlohaStore } from '../shared/store';

export function TeleopBadge() {
  const [isConnected, isSynced] = useAlohaStore(
    (s) => [s.isTeleopConnected, s.isSynced] as const,
    shallow,
  );

  const [badgeColor, badgeIcon, badgeMessage] = useMemo<
    [ComponentColor, IconKind, string]
  >(() => {
    if (!isConnected) {
      return ['Red', 'wifiSlash', 'Disconnected'];
    }

    if (isSynced) {
      return ['Green', 'checkmarkCircle', 'Synced'];
    }

    return ['Orange', 'xmarkCircle', 'Not synced'];
  }, [isConnected, isSynced]);

  return (
    <Badge color={badgeColor}>
      <Icon kind={badgeIcon} />
      <span>{badgeMessage}</span>
    </Badge>
  );
}
