import { useState } from 'react';
import { shallow } from 'zustand/shallow';

import {
  Button,
  Icon,
  NavigationBar,
  SettingsGroup,
  SettingsGroupItem,
  SettingsGroupNavigationItem,
  TextInput,
} from '@sb/design-system';

import { useAlohaStore } from '../shared/store';
import { WidgetModeToggleButton } from '../shared/WidgetModeToggleButton';

export function RecorderSkillsList() {
  const [recordings, setSelectedSkill] = useAlohaStore(
    (s) => [s.recordings, s.setSelectedSkill] as const,
    shallow,
  );

  const [newSkillName, setNewSkillName] = useState('');

  const skills = Object.keys(recordings);

  return (
    <>
      <NavigationBar
        contentRight={<WidgetModeToggleButton />}
        className="tw-pl-8 tw-pr-8"
      >
        Skills
      </NavigationBar>

      <section className="tw-flex-1 tw-flex tw-flex-col tw-overflow-auto tw-px-16 tw-pt-16">
        <SettingsGroup>
          {skills.map((skill) => {
            const numEpisodes = recordings[skill]?.length ?? 0;

            return (
              <SettingsGroupNavigationItem
                key={skill}
                label={
                  <div className="tw-flex tw-flex-col tw-py-10">
                    <span>{skill}</span>
                    <span className="tw-text-13 tw-text-label-secondary">
                      {numEpisodes} {numEpisodes === 1 ? 'episode' : 'episodes'}
                    </span>
                  </div>
                }
                onClick={() => setSelectedSkill(skill)}
              />
            );
          })}
        </SettingsGroup>
      </section>

      <p className="tw-text-13 tw-text-label-tertiary tw-px-32 tw-pt-8">
        {skills.length} {skills.length === 1 ? 'skill' : 'skills'} recorded.
      </p>

      <SettingsGroupItem isSeparated className="tw-m-16">
        <span>Create</span>
        <TextInput
          size={32}
          variant="Gray"
          value={newSkillName}
          onChange={setNewSkillName}
          className="tw-flex-1"
          placeholder="Enter name"
        />
        <Button
          variant="Filled"
          size={32}
          className="tw-rounded-6"
          disabled={newSkillName === '' || skills.includes(newSkillName)}
          onClick={() => setSelectedSkill(newSkillName)}
        >
          <Icon kind="plus" />
        </Button>
      </SettingsGroupItem>
    </>
  );
}
