import { useId } from 'react';
import { shallow } from 'zustand/shallow';

import {
  NavigationBar,
  NavigationBarBackButton,
  NumberInput,
  SettingsGroup,
  SettingsGroupItem,
  SettingsGroupSelectItem,
} from '@sb/design-system';
import { SelectActiveTCPOffsetOption } from '@sbrc/components/tcp-offset/SelectActiveTCPOffsetOption';

import { useMoveRobotViewContext } from '../../shared';
import { useJogParamsStore } from '../jog-params/store';

import { ApplyChangesButton } from './ApplyChangesButton';
import { MotionKindSwitch } from './MotionKindSwitch';
import { useSnapToAxisCalculator } from './useSnapToAxisCalculator';
import { WidgetModeToggleButton } from './WidgetModeToggleButton';

const ROTATION_LABEL = {
  X: 'Roll',
  Y: 'Pitch',
  Z: 'Yaw',
};

export function ToolControlSnapToAxisMode() {
  const { routine, isVizbot, setControlMode } = useMoveRobotViewContext();

  const [snapAxis, snapRotation, snapFrame, snapDirection, setJogSettings] =
    useJogParamsStore(
      (s) =>
        [
          s.snapAxis,
          s.snapRotation,
          s.snapFrame,
          s.snapDirection,
          s.setJogSettings,
        ] as const,
      shallow,
    );

  const calculatorState = useSnapToAxisCalculator();

  const inputID = useId();

  return (
    <>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={
          <NavigationBarBackButton
            onClick={() => setControlMode('toolControlTarget')}
          />
        }
        contentRight={<WidgetModeToggleButton />}
      >
        Snap to axis
      </NavigationBar>

      <section className="tw-flex tw-flex-col tw-flex-1 tw-overflow-auto tw-p-16">
        <SettingsGroup>
          <SettingsGroupSelectItem
            label="Axis"
            value={snapAxis}
            onChange={(event) =>
              setJogSettings({ snapAxis: event.target.value as any })
            }
          >
            <option value="X">X</option>
            <option value="Y">Y</option>
            <option value="Z">Z</option>
          </SettingsGroupSelectItem>

          <SettingsGroupItem>
            <label className="tw-flex-1" htmlFor={inputID}>
              {ROTATION_LABEL[snapAxis]}
            </label>

            <NumberInput
              id={inputID}
              size={32}
              variant="Gray"
              alignment="Center"
              className="tw-w-92"
              decimalPlaces={1}
              value={snapRotation}
              onChange={(newValue) =>
                setJogSettings({ snapRotation: newValue || 0 })
              }
            />

            <span className="tw-text-label-secondary tw-text-right tw-w-40">
              deg
            </span>
          </SettingsGroupItem>

          <SelectActiveTCPOffsetOption isVizbot={isVizbot} />

          <SettingsGroupSelectItem
            label="Frame"
            value={snapFrame}
            onChange={(event) =>
              setJogSettings({ snapFrame: event.target.value })
            }
          >
            <option value="base">Base</option>
            {routine?.space.map((spaceItem) => {
              if (spaceItem.kind === 'plane') {
                return (
                  <option key={spaceItem.id} value={spaceItem.id}>
                    {spaceItem.name}
                  </option>
                );
              }

              return null;
            })}
          </SettingsGroupSelectItem>

          <SettingsGroupSelectItem
            label="Direction"
            value={snapDirection}
            onChange={(event) =>
              setJogSettings({
                snapDirection: event.target.value === '+' ? '+' : '-',
              })
            }
          >
            <option value="+">Positive</option>
            <option value="-">Negative</option>
          </SettingsGroupSelectItem>

          <MotionKindSwitch />
        </SettingsGroup>

        <p className="tw-text-13 tw-text-label-tertiary tw-px-16 tw-pt-8">
          Additional frames can be defined in Space.
        </p>
      </section>

      <ApplyChangesButton
        isDisabled={calculatorState !== 'success'}
        label={
          calculatorState === 'noSolutions' ? 'Unreachable position' : undefined
        }
      />
    </>
  );
}
