import { shallow } from 'zustand/shallow';

import {
  Icon,
  NavigationBar,
  NavigationBarBackButton,
  SettingsGroup,
  SettingsGroupItem,
  SettingsGroupSliderItem,
} from '@sb/design-system';
import { useDistanceUnitInfo } from '@sbrc/hooks';

import {
  JOG_INCREMENTAL_ANGLE_INFO,
  JOG_INCREMENTAL_DISTANCE_INFO,
  JOG_MODES,
  getIncrementalDistanceString,
  type JogMode,
  getIncrementalAngleString,
} from './jogMode';
import { useJogParamsStore } from './store';

export function JogModeSettings() {
  const [setJogSettings, jogMode, jogIncrementalAngle, jogIncrementalDistance] =
    useJogParamsStore(
      (s) =>
        [
          s.setJogSettings,
          s.jogMode,
          s.jogIncrementalAngle,
          s.jogIncrementalDistance,
        ] as const,
      shallow,
    );

  const distanceUnit = useDistanceUnitInfo();

  return (
    <>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={
          <NavigationBarBackButton
            onClick={() => setJogSettings({ isJogModeSettingsView: false })}
          />
        }
      >
        Mode
      </NavigationBar>

      <div className="tw-flex tw-flex-col tw-flex-1 tw-overflow-auto tw-px-16 tw-pb-16 tw-gap-32">
        <section className="tw-flex tw-flex-col">
          <h5 className="tw-heading-40 tw-pl-16">Jog mode</h5>

          <SettingsGroup>
            {JOG_MODES.map((item) => (
              <SettingsGroupItem
                key={item.jogMode}
                onClick={() =>
                  setJogSettings({ jogMode: item.jogMode as JogMode })
                }
              >
                <Icon kind={item.iconKind} />
                <span className="tw-flex-1">{item.name}</span>
                {jogMode === item.jogMode && (
                  <Icon kind="checkmark" className="tw-text-orange" />
                )}
              </SettingsGroupItem>
            ))}
          </SettingsGroup>

          <p className="tw-text-13 tw-text-label-tertiary tw-px-16 tw-pt-8">
            With incremental jogging, the robot will only move once (by the
            specified step size) each time the jogging controls are tapped.
          </p>
        </section>

        <section className="tw-flex tw-flex-col">
          <h5 className="tw-heading-40 tw-pl-16">Step size</h5>

          <SettingsGroup>
            <SettingsGroupSliderItem
              className="[&_input]:tw-w-[160px]"
              secondaryLabel={getIncrementalDistanceString(
                jogIncrementalDistance,
                distanceUnit,
              )}
              min={1}
              max={JOG_INCREMENTAL_DISTANCE_INFO.length}
              step={1}
              value={jogIncrementalDistance}
              onChange={(e) =>
                setJogSettings({
                  jogIncrementalDistance: e.target.valueAsNumber,
                })
              }
              disabled={jogMode === 'continuous'}
            />
            <SettingsGroupSliderItem
              className="[&_input]:tw-w-[160px]"
              secondaryLabel={getIncrementalAngleString(jogIncrementalAngle)}
              min={1}
              max={JOG_INCREMENTAL_ANGLE_INFO.length}
              step={1}
              value={jogIncrementalAngle}
              onChange={(e) =>
                setJogSettings({ jogIncrementalAngle: e.target.valueAsNumber })
              }
              disabled={jogMode === 'continuous'}
            />
          </SettingsGroup>
        </section>
      </div>
    </>
  );
}
