import { shallow } from 'zustand/shallow';

import { SettingsGroupItem } from '@sb/design-system';
import { convertRadiansToDegrees } from '@sb/utilities';

import { useAlohaStore } from '../shared/store';

interface MiniArmJointStatusProps {
  jointName: string;
  jointNumber: number;
}

export function MiniArmJointStatus({
  jointName,
  jointNumber,
}: MiniArmJointStatusProps) {
  const [syncGap, isConnected] = useAlohaStore(
    (s) =>
      [
        s.syncGap[jointNumber]
          ? convertRadiansToDegrees(s.syncGap[jointNumber]).toFixed(1)
          : null,
        s.miniArmJointConnectivity[jointNumber],
      ] as const,
    shallow,
  );

  const getStatus = () => {
    if (!isConnected) {
      return <span className="tw-text-red">Disconnected</span>;
    }

    if (syncGap) {
      return <span className="tw-text-orange">Sync gap: {syncGap}°</span>;
    }

    return <span className="tw-text-green">Synced</span>;
  };

  return (
    <SettingsGroupItem key={jointName}>
      <span className="tw-flex-1">{jointName}</span>
      {getStatus()}
    </SettingsGroupItem>
  );
}
