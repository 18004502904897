import cx from 'classnames';

import { Icon } from '@sb/design-system';

import { useJogParamsStore } from '../jog-params/store';

import type { DegreeOfFreedom, Direction } from './dofInfo';
import { DOF_INFO } from './dofInfo';
import { ToolControlJogContinuousButton } from './ToolControlJogContinuousButton';
import { ToolControlJogIncrementalButton } from './ToolControlJogIncrementalButton';

interface ToolControlJogButtonProps {
  dof: DegreeOfFreedom;
  direction: Direction;
}

export function ToolControlJogButton({
  dof,
  direction,
}: ToolControlJogButtonProps) {
  const jogMode = useJogParamsStore((s) => s.jogMode);

  const ButtonComponent =
    jogMode === 'incremental'
      ? ToolControlJogIncrementalButton
      : ToolControlJogContinuousButton;

  const dofInfo = DOF_INFO[dof];
  const dofDirInfo = dofInfo[direction];

  return (
    <ButtonComponent
      dof={dof}
      direction={direction}
      color={dofInfo.buttonColor}
      variant="Flat"
      className={cx(
        'tw-h-auto',
        'tw-p-0',
        'tw-rounded-0',
        dofDirInfo.outerClassName,
      )}
      aria-label={dofDirInfo.ariaLabel}
    >
      <div
        className={cx('tw-flex', 'tw-items-center', dofDirInfo.innerClassName)}
      >
        <span className="tw-text-13 tw-text-label-secondary">
          {dofDirInfo.label}
        </span>
        <Icon
          kind={dofDirInfo.iconKind}
          className={dofInfo.iconClassName ?? 'tw-w-36'}
        />
      </div>
    </ButtonComponent>
  );
}
