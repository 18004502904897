import { Icon, NavigationBarButton } from '@sb/design-system';

import { useMoveRobotViewContext } from '../../shared';

export function WidgetModeToggleButton() {
  const { controlMode, setControlMode } = useMoveRobotViewContext();

  return (
    <NavigationBarButton
      data-testid="tool-control-mode-toggle"
      onClick={() => {
        setControlMode(
          controlMode === 'toolControl' ? 'toolControlTarget' : 'toolControl',
        );
      }}
      size={36}
      variant={controlMode === 'toolControl' ? 'Flat' : 'Filled'}
      className="tw-rounded-10"
    >
      <Icon kind="sliderHorizontal" />
    </NavigationBarButton>
  );
}
