import { useEffect, useState } from 'react';

import { areGripperStatesEqual } from '@sb/integrations/gripper-general';
import {
  useLiveRoutineRunnerHandle,
  useVizbotRoutineRunnerHandle,
} from '@sbrc/hooks';
import { areJointAnglesEqual } from '@sbrc/utils';

export default function useIsRobotSynced() {
  const livebot = useLiveRoutineRunnerHandle();

  const vizbot = useVizbotRoutineRunnerHandle();

  const [isArmSynced, setIsArmSynced] = useState(true);
  const [isGripperSynced, setIsGripperSynced] = useState(true);

  useEffect(() => {
    const unsubscribeActions = [
      livebot.onStateChange(
        (state) =>
          areJointAnglesEqual(
            state.kinematicState.jointAngles,
            vizbot.getState()?.kinematicState.jointAngles,
          ),
        Object.is,
        setIsArmSynced,
      ),

      livebot.onStateChange(
        (state) =>
          areGripperStatesEqual(
            state.kinematicState.gripperState,
            vizbot.getState()?.kinematicState.gripperState,
          ),
        Object.is,
        setIsGripperSynced,
      ),

      vizbot.onStateChange(
        (state) =>
          areJointAnglesEqual(
            state.kinematicState.jointAngles,
            livebot.getState()?.kinematicState.jointAngles,
          ),
        Object.is,
        setIsArmSynced,
      ),

      vizbot.onStateChange(
        (state) =>
          areGripperStatesEqual(
            state.kinematicState.gripperState,
            livebot.getState()?.kinematicState.gripperState,
          ),
        Object.is,
        setIsGripperSynced,
      ),
    ];

    return () => {
      unsubscribeActions.forEach((unsubscribe) => unsubscribe());
    };
  }, [vizbot, livebot]);

  return {
    isArmSynced,
    isGripperSynced,
    isPositionsSynced: isArmSynced && isGripperSynced,
  };
}
