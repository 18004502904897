/* eslint-disable jsx-a11y/media-has-caption, @next/next/no-img-element */

import cx from 'classnames';
import { useState } from 'react';

import { CameraStream, RotationOverlay } from '../camera';

interface RobotLiveStreamProps {
  className?: string;
  isRotationEnabled?: boolean;
}

export function RobotLiveStream({
  className,
  isRotationEnabled = false,
}: RobotLiveStreamProps) {
  const [rotation, setRotation] = useState<number>(0);

  return (
    <div
      className={cx(
        'tw-flex',
        'tw-items-center',
        'tw-justify-center',
        className,
      )}
    >
      <CameraStream
        rotation={rotation}
        className={cx(
          'tw-surface-secondary',
          'tw-shadow-80',
          'tw-rounded-18',
          'tw-overflow-hidden',
        )}
      >
        {isRotationEnabled && (
          <RotationOverlay rotation={rotation} setRotation={setRotation} />
        )}
      </CameraStream>
    </div>
  );
}
