import * as zod from 'zod';

export const RecorderMessageOut = zod.discriminatedUnion('kind', [
  zod.object({ kind: zod.literal('subscribe') }),
  zod.object({
    kind: zod.literal('start_recording'),
    metadata: zod.object({
      task_name: zod.string(),
      episode_name: zod.string(),
    }),
  }),
  zod.object({ kind: zod.literal('stop_recording') }),
  zod.object({
    kind: zod.literal('add_bot'),
    metadata: zod.object({
      robot_id: zod.string(),
    }),
  }),
]);

export type RecorderMessageOut = zod.infer<typeof RecorderMessageOut>;

const RecorderState = zod.union([
  zod.literal('recording'),
  zod.literal('stopped'),
  zod.literal('playing_episode'),
]);

export type RecorderState = zod.infer<typeof RecorderState>;

export const RecorderMessageIn = zod.discriminatedUnion('kind', [
  zod.object({
    kind: zod.literal('record_state_update'),
    state: zod.object({
      record_state: RecorderState,
      recordingsMetadata: zod.record(zod.array(zod.string())),
      managed_bot_ids: zod.array(zod.string()),
    }),
  }),
  zod.object({
    kind: zod.literal('message'),
    message: zod.string(),
  }),
  zod.object({
    kind: zod.literal('error'),
    message: zod.string(),
  }),
]);

export type RecorderMessageIn = zod.infer<typeof RecorderMessageIn>;
