import { useMemo } from 'react';

import { applyCompoundPose } from '@sb/geometry';
import type { KinematicState } from '@sb/routine-runner';
import { useRobotInvertedTCPTransform } from '@sbrc/hooks';
import type { EulerPose } from '@sbrc/utils';
import { convertEulerPose, convertJointAngles } from '@sbrc/utils';

import type { ControlModeState, TargetJointAnglesState } from './shared';

export function useGhostRobotState(
  controlMode: ControlModeState,
  isVizbot: boolean,
  targetJointAnglesDegrees: TargetJointAnglesState,
  targetEulerPose: EulerPose | null,
): Partial<KinematicState> | null {
  const invertedTCPTransform = useRobotInvertedTCPTransform({ isVizbot });

  const jointAnglesDegrees = isVizbot
    ? targetJointAnglesDegrees.vizbot
    : targetJointAnglesDegrees.liveRobot;

  return useMemo(() => {
    if (
      controlMode === 'jointControlDual' ||
      controlMode === 'toolControlSnapToAxis' ||
      controlMode === 'space'
    ) {
      if (jointAnglesDegrees) {
        return {
          jointAngles: convertJointAngles.fromDegrees(jointAnglesDegrees),
        };
      }
    }

    if (
      controlMode === 'toolControlTarget' ||
      controlMode === 'toolControlSnapToAxis'
    ) {
      if (targetEulerPose && invertedTCPTransform) {
        const targetTCPPose = convertEulerPose.toCartesian(targetEulerPose);

        const wristPose = applyCompoundPose(
          invertedTCPTransform,
          targetTCPPose,
        );

        return { wristPose };
      }
    }

    return null;
  }, [controlMode, jointAnglesDegrees, targetEulerPose, invertedTCPTransform]);
}
