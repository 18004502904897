import {
  Modal,
  NavigationBar,
  NavigationBarButton,
  SegmentedButton,
} from '@sb/design-system';

import type { RotationState, RotationUnit } from './types';

interface ToolCoordinateRotationTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRotationChange: (rotation: RotationState) => void;
  onUnitChange: (unit: RotationUnit) => void;
  rotation: RotationState;
  rotationUnit: RotationUnit;
}

export function ToolCoordinateRotationTypeModal({
  isOpen,
  onClose,
  onRotationChange,
  onUnitChange,
  rotation,
  rotationUnit,
}: ToolCoordinateRotationTypeModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="tw-w-512">
      <NavigationBar
        contentLeft={
          <NavigationBarButton onClick={onClose}>Done</NavigationBarButton>
        }
      >
        Tool pose settings
      </NavigationBar>

      <section className="tw-px-24 tw-pb-24 tw-flex tw-flex-col tw-gap-16">
        <fieldset className="tw-flex tw-flex-col">
          <h5 className="tw-heading-40">Rotation type</h5>
          <div className="tw-flex">
            <SegmentedButton
              className="tw-flex-1"
              checked={rotation === 'euler'}
              onChange={() => onRotationChange('euler')}
            >
              Euler angles
            </SegmentedButton>
            <SegmentedButton
              className="tw-flex-1"
              checked={rotation === 'quaternion'}
              onChange={() => onRotationChange('quaternion')}
            >
              Quaternion
            </SegmentedButton>
          </div>
        </fieldset>

        <fieldset className="tw-flex tw-flex-col">
          <h5 className="tw-heading-40">Rotation unit</h5>
          <div className="tw-flex">
            <SegmentedButton
              className="tw-flex-1"
              checked={rotationUnit === 'degrees'}
              onChange={() => onUnitChange('degrees')}
              disabled={rotation === 'quaternion'}
            >
              Degrees
            </SegmentedButton>
            <SegmentedButton
              className="tw-flex-1"
              checked={rotationUnit === 'radians'}
              onChange={() => onUnitChange('radians')}
              disabled={rotation === 'quaternion'}
            >
              Radians
            </SegmentedButton>
          </div>
        </fieldset>
      </section>
    </Modal>
  );
}
