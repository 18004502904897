import {
  DEFAULT_TCP_OFFSET_OPTION,
  type TCPOffsetOption,
} from '@sb/motion-planning';
import type { RoutineRunnerState } from '@sb/routine-runner';
import type { UseRoutineRunnerHandleArguments } from '@sbrc/hooks';
import { useRobotState } from '@sbrc/hooks';

function tcpOffsetOptionSelector(
  state: RoutineRunnerState | null,
): TCPOffsetOption {
  return state?.kinematicState.tcpOffsetOption ?? DEFAULT_TCP_OFFSET_OPTION;
}

export function useActiveTCPOffsetOption({
  isVizbot,
}: UseRoutineRunnerHandleArguments): TCPOffsetOption {
  return useRobotState({ isVizbot }, tcpOffsetOptionSelector, Object.is);
}
