import {
  NavigationBar,
  SettingsGroup,
  SettingsGroupNavigationItem,
} from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';
import { EulerPoseSettingsGroup } from '@sbrc/components/euler-pose/EulerPoseSettingsGroup';
import { useDistanceUnitInfo, useRobotTooltipState } from '@sbrc/hooks';
import type { EulerPose } from '@sbrc/utils';
import { convertEulerPose } from '@sbrc/utils';

import { useMoveRobotViewContext } from '../../shared';
import { useTargetEulerPose } from '../jog-params/store';

import { ApplyChangesButton } from './ApplyChangesButton';
import { MotionKindSwitch } from './MotionKindSwitch';
import { ToolControlTargetModeMoreMenu } from './ToolControlTargetModeMoreMenu';
import { WidgetModeToggleButton } from './WidgetModeToggleButton';

interface ToolControlTargetModeProps {
  onSnapToAxisMode: () => void;
}

export function ToolControlTargetMode({
  onSnapToAxisMode,
}: ToolControlTargetModeProps) {
  const { isVizbot } = useMoveRobotViewContext();

  const [targetPose, setTargetEulerPose] = useTargetEulerPose(isVizbot);

  // Cartesian
  const tooltip: CartesianPose | null = useRobotTooltipState({ isVizbot });

  // Euler
  const tooltipPose: EulerPose =
    convertEulerPose.fromNullableCartesian(tooltip);

  const distanceUnitInfo = useDistanceUnitInfo();

  return (
    <>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={<ToolControlTargetModeMoreMenu />}
        contentRight={<WidgetModeToggleButton />}
      >
        Tooltip
      </NavigationBar>

      <div className="tw-flex-1 tw-flex tw-flex-col tw-overflow-auto tw-p-16 tw-gap-16">
        <EulerPoseSettingsGroup
          distanceUnitInfo={distanceUnitInfo}
          value={targetPose ?? tooltipPose}
          onChange={(pose) => setTargetEulerPose(pose)}
        />

        <SettingsGroup>
          <MotionKindSwitch />

          <SettingsGroupNavigationItem
            label="Snap to axis"
            onClick={onSnapToAxisMode}
          />
        </SettingsGroup>
      </div>

      <ApplyChangesButton />
    </>
  );
}
