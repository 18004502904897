import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import type { ButtonProps } from '@sb/design-system';
import { Button } from '@sb/design-system';
import { wait } from '@sb/utilities';
import getAdHocSpeedProfile from '@sbrc/components/visualizer-view-shared/getAdHocSpeedProfile';
import {
  useDistanceUnitInfo,
  useFeatureFlag,
  useGuidedMode,
  useRobotStateKind,
  useRobotTooltipState,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';

import { useMoveRobotViewContext } from '../../shared';
import { useJogParamsStore } from '../jog-params/store';

import { calculateIncrementalJogTarget } from './calculateIncrementalJogTarget';
import { DOF_INFO, type DegreeOfFreedom, type Direction } from './dofInfo';

interface ToolControlJogIncrementalButtonProps extends ButtonProps {
  dof: DegreeOfFreedom;
  direction: Direction;
}

export function ToolControlJogIncrementalButton({
  dof,
  direction,
  ...rest
}: ToolControlJogIncrementalButtonProps) {
  const { isVizbot, robot } = useMoveRobotViewContext();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const routineRunnerStateKind = useRobotStateKind({ isVizbot });

  const { runAdHocCommand } = useGuidedMode({ isVizbot });

  const tooltipPose = useRobotTooltipState({ isVizbot });

  const [isJogPossible, setIsJogPossible] = useState(false);

  const isDisabled =
    !isJogPossible ||
    // user should not interact with tool control if state hasn't loaded
    !tooltipPose ||
    // or if the robot is not idle
    routineRunnerStateKind !== 'Idle';

  const [frameOfReference, jogIncrementalDistance, jogIncrementalAngle] =
    useJogParamsStore(
      (s) =>
        [
          s.frameOfReference,
          s.jogIncrementalDistance,
          s.jogIncrementalAngle,
        ] as const,
      shallow,
    );

  const distanceUnitInfo = useDistanceUnitInfo();

  const dofInfo = DOF_INFO[dof];
  const mappedDof = frameOfReference === 'tooltip' ? dofInfo.baseDOF : dof;
  const offsetPose = DOF_INFO[mappedDof][direction].offset;

  useEffect(() => {
    if (!tooltipPose) {
      return undefined;
    }

    setIsJogPossible(false);

    let cancelled = false;

    const calculate = async () => {
      await wait(200);

      if (cancelled) {
        return;
      }

      const targetPose = calculateIncrementalJogTarget({
        tooltipPose,
        offsetPose,
        frameOfReference,
        distanceUnitInfo,
        jogIncrementalDistance,
        jogIncrementalAngle,
      });

      const targetJointAngles =
        await routineRunnerHandle.getJointAnglesForCartesianSpacePose(
          targetPose,
          'line',
        );

      if (cancelled) {
        return;
      }

      setIsJogPossible(targetJointAngles !== null);
    };

    calculate();

    return () => {
      cancelled = true;
    };
  }, [
    tooltipPose,
    offsetPose,
    frameOfReference,
    distanceUnitInfo,
    jogIncrementalDistance,
    jogIncrementalAngle,
    routineRunnerHandle,
  ]);

  const handleClick = async () => {
    if (!tooltipPose) {
      return;
    }

    const targetPose = calculateIncrementalJogTarget({
      tooltipPose,
      offsetPose,
      frameOfReference,
      distanceUnitInfo,
      jogIncrementalDistance,
      jogIncrementalAngle,
    });

    await runAdHocCommand({
      onRunCommand: async () => {
        const speedProfile = await getAdHocSpeedProfile(
          robot.id,
          isVizbot,
          isAdHocFullSpeed,
        );

        await routineRunnerHandle.moveToCartesianSpacePose(
          targetPose,
          'line',
          speedProfile,
        );
      },
    });
  };

  return <Button disabled={isDisabled} onClick={handleClick} {...rest} />;
}
