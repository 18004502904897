import { useCallback, useEffect, useState } from 'react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import type { CartesianPose } from '@sb/geometry';
import type { FrameOfReference, MotionKind } from '@sb/motion-planning';
import { convertEulerPose, type EulerPose } from '@sbrc/utils';

import type { JogMode } from './jogMode';

interface JogParams {
  liveTargetEulerPose: EulerPose | null;
  vizbotTargetEulerPose: EulerPose | null;
  frameOfReference: FrameOfReference;
  motionKind: MotionKind;
  snapAxis: 'X' | 'Y' | 'Z';
  snapRotation: number;
  snapFrame: string;
  snapDirection: '+' | '-';
  isJogModeSettingsView: boolean;
  jogMode: JogMode;
  jogIncrementalDistance: number;
  jogIncrementalAngle: number;
}

interface JogParamsStore extends JogParams {
  setJogSettings: (data: Partial<JogParams>) => void;
}

export const useJogParamsStore = create<JogParamsStore>((set) => {
  return {
    liveTargetEulerPose: null,
    vizbotTargetEulerPose: null,
    frameOfReference: 'robotArm',
    motionKind: 'joint',
    snapAxis: 'Z',
    snapRotation: 0,
    snapFrame: 'base',
    snapDirection: '-',
    isJogModeSettingsView: false,
    jogMode: 'continuous',
    jogIncrementalDistance: 1,
    jogIncrementalAngle: 1,
    setJogSettings: (data) => set(data),
  };
});

export function useTargetEulerPose(isVizbot: boolean) {
  const [liveTargetEulerPose, vizbotTargetEulerPose, setJogSettings] =
    useJogParamsStore(
      (s) =>
        [
          s.liveTargetEulerPose,
          s.vizbotTargetEulerPose,
          s.setJogSettings,
        ] as const,
      shallow,
    );

  const targetEulerPose = isVizbot
    ? vizbotTargetEulerPose
    : liveTargetEulerPose;

  const setTargetEulerPose = useCallback(
    (pose: EulerPose | null) => {
      if (isVizbot) {
        setJogSettings({ vizbotTargetEulerPose: pose });
      } else {
        setJogSettings({ liveTargetEulerPose: pose });
      }
    },
    [isVizbot, setJogSettings],
  );

  return [targetEulerPose, setTargetEulerPose] as const;
}

/** used in move view modal, to initialize from move arm step config */
export function useInitializeTargetEulerPose(
  cartesianPose: CartesianPose | undefined,
) {
  const setJogSettings = useJogParamsStore((s) => s.setJogSettings);

  // we only care about the value of this on mount, so store it in state
  const [initialCartesianPose] = useState(cartesianPose);

  useEffect(() => {
    const initialEulerPose = initialCartesianPose
      ? convertEulerPose.fromCartesian(initialCartesianPose)
      : null;

    setJogSettings({
      liveTargetEulerPose: initialEulerPose,
      vizbotTargetEulerPose: initialEulerPose,
    });
  }, [initialCartesianPose, setJogSettings]);
}
