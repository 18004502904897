import { Icon, NavigationBarButton } from '@sb/design-system';

import { useMoveRobotViewContext } from '../../shared';

export function WidgetModeToggleButton() {
  const { controlMode, setControlMode } = useMoveRobotViewContext();

  const isTargetMode = controlMode === 'jointControlDual';

  return (
    <NavigationBarButton
      onClick={() =>
        setControlMode(isTargetMode ? 'jointControlSingle' : 'jointControlDual')
      }
      variant={isTargetMode ? 'Filled' : 'Flat'}
      size={36}
      data-testid="joint-control-mode-toggle"
      className="tw-rounded-10"
    >
      <Icon kind="sliderHorizontal" />
    </NavigationBarButton>
  );
}
