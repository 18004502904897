import { useEffect } from 'react';

import { useFeatureFlag } from '@sbrc/hooks';

import { EquipmentWidgetIconButtons } from '../visualizer-view-shared/widget-panel/EquipmentWidgetIconButtons';
import { WidgetIconButton } from '../visualizer-view-shared/widget-panel/WidgetIconButton';

import {
  useControlModeButtonGroupStore,
  useMoveRobotViewContext,
} from './shared';

interface ControlModeButtonGroupProps {
  isDisabled: boolean;
}

export function ControlModeButtonGroup({
  isDisabled,
}: ControlModeButtonGroupProps) {
  const {
    controlMode,
    isVizbot,
    setControlMode,
    routine,
    isCameraView,
    setIsCameraView,
  } = useMoveRobotViewContext();

  const enableCamera = useFeatureFlag('camera');
  const enableAlohaControl = useFeatureFlag('alohaControl');

  const { jointControlMode, toolControlMode, onChangeControlMode } =
    useControlModeButtonGroupStore();

  useEffect(() => {
    onChangeControlMode(controlMode);
  }, [onChangeControlMode, controlMode]);

  const isRoutineLoaded = !!routine;

  const commonIconButtonProps = {
    activeWidget: controlMode ?? (isCameraView ? 'camera' : null),
    setActiveWidget: setControlMode,
    disabled: isDisabled,
    isVizbot,
  };

  return (
    <div className="tw-flex tw-gap-16">
      {enableCamera && !isVizbot && (
        <WidgetIconButton
          {...commonIconButtonProps}
          disabled={false}
          activeWidget={isCameraView ? 'camera' : controlMode}
          setActiveWidget={(widget) => setIsCameraView(widget === 'camera')}
          forWidget="camera"
          iconKind="equipmentCamera"
          label="Camera"
          tooltip="Show camera stream"
        />
      )}

      <WidgetIconButton
        {...commonIconButtonProps}
        forWidget={toolControlMode}
        label="Tooltip"
        iconKind="tooltip"
        tooltip="Control the position and orientation of the arm"
        data-testid="move-robot-controls-tools-button"
      />

      <WidgetIconButton
        {...commonIconButtonProps}
        forWidget={jointControlMode}
        label="Joints"
        iconKind="joint"
        tooltip="Control individual joint angles"
        data-testid="move-robot-controls-joints-button"
      />

      <EquipmentWidgetIconButtons {...commonIconButtonProps} />

      <WidgetIconButton
        {...commonIconButtonProps}
        forWidget="space"
        label="Space"
        iconKind="viewfinderBox"
        tooltip="Configure positions around the robot's working space to use in routines"
        disabled={isDisabled || !isRoutineLoaded}
        data-testid="move-robot-controls-space-button"
      />

      <WidgetIconButton
        {...commonIconButtonProps}
        forWidget="io"
        label="I/O"
        iconKind="powerplug"
        tooltip="View I/O interface tooling"
        data-testid="move-robot-controls-io-button"
      />

      {enableAlohaControl && (
        <WidgetIconButton
          {...commonIconButtonProps}
          forWidget="alohaControl"
          label="Demonstration control"
          iconKind="sparkles"
          tooltip="Demonstration control"
        />
      )}
    </div>
  );
}
