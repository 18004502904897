import { RobotLiveStream } from '@sbrc/components/robot-live-stream';

import { useMoveRobotViewContext } from './shared';

import styles from './MoveRobotLiveStream.module.css';

export default function MoveRobotLiveStream() {
  const { isCameraView } = useMoveRobotViewContext();

  if (!isCameraView) {
    return null;
  }

  return (
    <RobotLiveStream className={styles.robotLiveStream} isRotationEnabled />
  );
}
