import { create } from 'zustand';

import type { ControlModeState } from './shared';

interface ControlModeButtonGroupStore {
  toolControlMode: ControlModeState & string;
  jointControlMode: ControlModeState & string;
  onChangeControlMode: (controlMode: ControlModeState) => void;
}

/**
 * Remember the latest dual/single mode for the tool and joint widgets
 * so when they are closed and reopened they show the expected mode
 */
export const useControlModeButtonGroupStore =
  create<ControlModeButtonGroupStore>((set) => ({
    toolControlMode: 'toolControl',
    jointControlMode: 'jointControlSingle',
    onChangeControlMode: (controlMode) => {
      switch (controlMode) {
        case 'toolControl':
        case 'toolControlTarget':
        case 'toolControlSnapToAxis':
          set({ toolControlMode: controlMode });
          break;
        case 'jointControlSingle':
        case 'jointControlDual':
          set({ jointControlMode: controlMode });
          break;
        default:
          break;
      }
    },
  }));
