import WidgetView from '../../../visualizer-view-shared/widget-panel/WidgetView';
import { useMoveRobotViewContext } from '../../shared';
import { JogModeSettings } from '../jog-params/JogModeSettings';
import { useJogParamsStore } from '../jog-params/store';

import { JointControl } from './JointControl';
import { JointControlTargetMode } from './JointControlTargetMode';

export function MoveRobotJointControlWidget() {
  const { controlMode } = useMoveRobotViewContext();

  const isJogModeSettingsView = useJogParamsStore(
    (s) => s.isJogModeSettingsView,
  );

  const getContent = () => {
    if (isJogModeSettingsView) {
      return <JogModeSettings />;
    }

    if (controlMode === 'jointControlDual') {
      return <JointControlTargetMode />;
    }

    return <JointControl />;
  };

  return <WidgetView isFullHeight>{getContent()}</WidgetView>;
}
