import { SettingsGroupSelectItem } from '@sb/design-system';
import type { TCPOffsetOption } from '@sb/motion-planning';

import { findTCPOffsetOptionOrDefault } from './findTCPOffsetOptionOrDefault';
import { useTCPOffsetOptions } from './useTCPOffsetOptions';

interface SelectTCPOffsetOptionProps {
  className?: string;
  value: TCPOffsetOption;
  onChange: (value: TCPOffsetOption) => void;
  isSeparated?: boolean;
}

export function SelectTCPOffsetOption({
  className,
  value,
  onChange,
  isSeparated,
}: SelectTCPOffsetOptionProps) {
  const offsetOptions = useTCPOffsetOptions();

  return (
    <SettingsGroupSelectItem
      label="Tooltip"
      value={findTCPOffsetOptionOrDefault(value, offsetOptions).tcpOffsetOption}
      onChange={(e) => {
        onChange(
          findTCPOffsetOptionOrDefault(e.target.value, offsetOptions)
            .tcpOffsetOption,
        );
      }}
      isSeparated={isSeparated}
      className={className}
    >
      {offsetOptions.map(({ tcpOffsetOption, name }) => (
        <option key={tcpOffsetOption} value={tcpOffsetOption}>
          {name}
        </option>
      ))}
    </SettingsGroupSelectItem>
  );
}
