import { Menu, MenuItem, NavigationBarMenuButton } from '@sb/design-system';
import { useRoutineRunnerHandle } from '@sbrc/hooks';
import { convertJointAngles } from '@sbrc/utils';

import { useMoveRobotViewContext } from '../../shared';

import useTargetJointAngles from './useTargetJointAngles';

export function JointControlTargetModeMoreMenu() {
  const { isVizbot } = useMoveRobotViewContext();

  const otherRobotRoutineRunnerHandle = useRoutineRunnerHandle({
    isVizbot: !isVizbot,
  });

  const { resetAllTargetAngles, targetJointAngles, setAllTargetJointAngles } =
    useTargetJointAngles();

  const hasTargets = Boolean(targetJointAngles);

  return (
    <NavigationBarMenuButton
      data-testid="joint-control-more-menu"
      placement="bottom-start"
      content={
        <Menu>
          <MenuItem
            data-testid="joint-control-import-position"
            onClick={() => {
              const otherRobotTargetJointAngles =
                otherRobotRoutineRunnerHandle.getState()?.kinematicState
                  .jointAngles;

              if (otherRobotTargetJointAngles) {
                setAllTargetJointAngles(
                  convertJointAngles.toDegrees(otherRobotTargetJointAngles),
                );
              }
            }}
            iconKind="arrowDownSquare"
          >
            Import {isVizbot ? 'live robot' : 'visualizer'} position
          </MenuItem>

          <MenuItem
            data-testid="joint-control-reset-all"
            onClick={resetAllTargetAngles}
            disabled={!hasTargets}
            iconKind="arrowUturnLeft"
          >
            Reset all
          </MenuItem>
        </Menu>
      }
    />
  );
}
