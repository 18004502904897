import cx from 'classnames';

import { roundToDecimalPlaces } from '@sb/utilities';

interface ToolCoordinateProps {
  color?: 'X' | 'Y' | 'Z';
  coordinateLabel: string;
  decimalPlaces: number;
  unitLabel?: string;
  value: number;
}

export function ToolCoordinate({
  color,
  coordinateLabel,
  decimalPlaces,
  unitLabel,
  value,
}: ToolCoordinateProps) {
  const valueString = Number.isNaN(value)
    ? 'Loading...'
    : roundToDecimalPlaces(value, decimalPlaces);

  return (
    <div className="tw-flex tw-justify-between tw-text-15 tw-gap-8">
      <span
        className={cx(
          'tw-mr-auto',
          color === 'X' && 'tw-text-red',
          color === 'Y' && 'tw-text-green',
          color === 'Z' && 'tw-text-blue',
        )}
      >
        {coordinateLabel}
      </span>

      <span className="tw-text-ellipsis">{valueString}</span>

      <span className="tw-text-label-secondary">{unitLabel}</span>
    </div>
  );
}
