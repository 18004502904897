import cx from 'classnames';
import { useState } from 'react';

import type { IconKind } from '@sb/design-system';
import { Icon, Popper } from '@sb/design-system';

interface JogParamsButtonProps {
  menu?: React.ReactNode;
  onClick?: () => void;
  iconKind: IconKind;
  label1: string;
  label2: string;
  isDisabled?: boolean;
}

export function JogParamsButton({
  menu,
  onClick,
  iconKind,
  label1,
  label2,
  isDisabled,
}: JogParamsButtonProps) {
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const containerClassName = cx(
    'tw-flex-1',
    'tw-overflow-hidden',
    'tw-flex',
    'tw-flex-col',
    'tw-items-stretch',
    'tw-py-8',
    'tw-px-4',
    'tw-rounded-10',
    'tw-surface-elevated',
    'aria-disabled:tw-opacity-40',
    'aria-disabled:tw-cursor-not-allowed',
  );

  const content = (
    <>
      <Icon kind={iconKind} className="tw-text-label-secondary tw-mx-auto" />
      <span className="tw-text-13 tw-text-label-tertiary tw-mt-4 tw-text-center">
        {label1}
      </span>
      <span className="tw-text-13 tw-text-ellipsis tw-text-center">
        {label2}
      </span>
    </>
  );

  if (menu) {
    return (
      <Popper
        isOpen={isPopperOpen}
        onClose={() => setIsPopperOpen(false)}
        content={menu}
        className={containerClassName}
        onClick={() => setIsPopperOpen(!isPopperOpen && !isDisabled)}
        placement="top"
        fallbackPlacements={[]}
        offset={8}
        aria-disabled={isDisabled}
      >
        {content}
      </Popper>
    );
  }

  return (
    <div
      role="button"
      className={containerClassName}
      onClick={() => !isDisabled && onClick?.()}
      aria-disabled={isDisabled}
    >
      {content}
    </div>
  );
}
