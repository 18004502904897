import { IOWidget } from '../../visualizer-view-shared/io/IOWidget';
import { useMoveRobotViewContext } from '../shared';

const MoveRobotIOWidget = () => {
  const { isVizbot, robot } = useMoveRobotViewContext();

  return <IOWidget isVizbot={isVizbot} robot={robot} />;
};

export default MoveRobotIOWidget;
