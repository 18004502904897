import React from 'react';

import { HoldableButton } from '@sb/design-system';
import {
  useFeatureFlag,
  useGuidedMode,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';
import { arePosesEqual, convertEulerPose } from '@sbrc/utils';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { useMoveRobotViewContext } from '../../shared';
import { useTargetEulerPose, useJogParamsStore } from '../jog-params/store';

interface ApplyChangesButtonProps {
  isDisabled?: boolean;
  label?: React.ReactNode;
}

export function ApplyChangesButton({
  isDisabled,
  label,
}: ApplyChangesButtonProps) {
  const { robot, isVizbot } = useMoveRobotViewContext();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const { setToast } = useToast();

  const motionKind = useJogParamsStore((s) => s.motionKind);

  const [targetPose, setTargetEulerPose] = useTargetEulerPose(isVizbot);

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const onHoldApplyChanges = () => {
    if (targetPose) {
      const targetCartesianPose = convertEulerPose.toCartesian(targetPose);

      const moveRobotToTargetPosition = async () => {
        const speedProfile = await getAdHocSpeedProfile(
          robot.id,
          isVizbot,
          isAdHocFullSpeed,
        );

        return routineRunnerHandle.moveToCartesianSpacePose(
          targetCartesianPose,
          motionKind,
          speedProfile,
        );
      };

      runAdHocCommand({
        onRunCommand: moveRobotToTargetPosition,
        onError(error) {
          setToast({
            kind: 'error',
            message: `Error setting pose target: ${error}`,
          });
        },
        onSuccess() {
          const tooltipPose =
            routineRunnerHandle.getState()?.kinematicState.tooltipPoint ?? null;

          if (arePosesEqual(tooltipPose, targetCartesianPose)) {
            setTargetEulerPose(null);
          }
        },
      });
    }
  };

  return (
    <>
      <hr className="tw-border-divider-primary" />

      <HoldableButton
        variant="Filled"
        onHold={onHoldApplyChanges}
        onRelease={stopGuidedMode}
        disabled={!targetPose || isDisabled}
        className="tw-rounded-6 tw-m-16"
        data-testid="move-robot-control-panel-widgets-tool-control-button"
      >
        {label ?? 'Hold to apply changes'}
      </HoldableButton>
    </>
  );
}
