import { shallow } from 'zustand/shallow';

import { Icon, NavigationBarButton } from '@sb/design-system';

import { useAlohaStore } from './store';

export function WidgetModeToggleButton() {
  const [widgetMode, setWidgetMode] = useAlohaStore(
    (s) => [s.widgetMode, s.setWidgetMode] as const,
    shallow,
  );

  return (
    <NavigationBarButton
      size={36}
      className="tw-rounded-10"
      variant={widgetMode === 'recorder' ? 'Filled' : 'Flat'}
      onClick={() =>
        setWidgetMode(widgetMode === 'teleop' ? 'recorder' : 'teleop')
      }
    >
      <Icon kind="video" />
    </NavigationBarButton>
  );
}
