import type { Integrations } from '@sb/remote-control/types';
import { useEquipment } from '@sbrc/hooks';

import { getTCPOffsetOptions } from './getTCPOffsetOptions';

export function useTCPOffsetOptions(): Integrations.TCPOffsetOptionsAndNames {
  const equipment = useEquipment();
  const offsetOptions = getTCPOffsetOptions(equipment ?? []);

  return offsetOptions;
}
