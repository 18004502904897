import type { Integrations } from '@sb/remote-control/types';

export function findTCPOffsetOptionOrDefault(
  valueToFind: number | string,
  offsetOptions: Integrations.TCPOffsetOptionsAndNames,
) {
  // value may come from `<select onChange>` and need converting to int
  const numericValueToFind =
    typeof valueToFind === 'string'
      ? Number.parseInt(valueToFind, 10)
      : valueToFind;

  return (
    offsetOptions.find(
      ({ tcpOffsetOption }) =>
        tcpOffsetOption === valueToFind ||
        tcpOffsetOption === numericValueToFind,
    ) ?? offsetOptions[0]
  );
}
