import { useState } from 'react';

import type { ButtonProps } from '@sb/design-system';
import { HoldableButton } from '@sb/design-system';
import type {
  ArmJointLimits,
  ArmJointVelocities,
  JointNumber,
} from '@sb/motion-planning';
import type { SpeedProfile } from '@sb/routine-runner';
import {
  useFeatureFlag,
  useGuidedMode,
  useRobotStateKind,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';

import getAdHocSpeedProfile from '../../../visualizer-view-shared/getAdHocSpeedProfile';
import { useMoveRobotViewContext } from '../../shared';

interface JointControlJogContinuousButtonProps extends ButtonProps {
  jointNumber: JointNumber;
  direction: 1 | -1;
  jointAngle?: number;
  limits?: ArmJointLimits[0];
}

export function JointControlJogContinuousButton({
  jointNumber,
  direction,
  jointAngle,
  limits,
  ...rest
}: JointControlJogContinuousButtonProps) {
  const { isVizbot, robot } = useMoveRobotViewContext();

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const routineRunnerStateKind = useRobotStateKind({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const [isJogging, setIsJogging] = useState(false);

  const isDisabled =
    !isJogging &&
    (routineRunnerStateKind !== 'Idle' ||
      jointAngle === undefined ||
      limits === undefined ||
      (direction === -1 && jointAngle <= limits.min) ||
      (direction === 1 && jointAngle >= limits.max));

  const onHoldSpinButton = async () => {
    if (isDisabled) {
      return;
    }

    setIsJogging(true);

    await runAdHocCommand({
      onRunCommand: async (): Promise<void> => {
        const speedProfile = await getAdHocSpeedProfile(
          robot.id,
          isVizbot,
          isAdHocFullSpeed,
        );

        const jointRelativeSpeedProfile: SpeedProfile = {
          ...speedProfile,
          maxJointSpeeds: speedProfile.maxJointSpeeds.map((jointSpeed) => {
            // sign reflects direction
            return jointSpeed * direction;
          }) as ArmJointVelocities,
        };

        await routineRunnerHandle.moveJointRelative(
          jointNumber,
          jointRelativeSpeedProfile,
        );
      },
    });

    setIsJogging(false);
  };

  return (
    <HoldableButton
      onHold={() => onHoldSpinButton()}
      onRelease={stopGuidedMode}
      disabled={isDisabled}
      {...rest}
    />
  );
}
