import { Menu, MenuItem } from '@sb/design-system';
import { useActiveTCPOffsetOption } from '@sbrc/components/tcp-offset/useActiveTCPOffsetOption';
import { useTCPOffsetOptions } from '@sbrc/components/tcp-offset/useTCPOffsetOptions';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

import { useMoveRobotViewContext } from '../../shared';

import { JogParamsButton } from './JogParamsButton';

export function TCPSelector() {
  const { isVizbot, controlMode } = useMoveRobotViewContext();
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });
  const tcpOffsetOptions = useTCPOffsetOptions();
  const tcpOffsetOption = useActiveTCPOffsetOption({ isVizbot });

  const tcpOffsetOptionAndName = tcpOffsetOptions.find(
    (o) => o.tcpOffsetOption === tcpOffsetOption,
  ) ?? {
    tcpOffsetOption,
    name: '',
    iconKind: 'blank',
  };

  return (
    <JogParamsButton
      menu={
        <Menu>
          {tcpOffsetOptions.map((o) => (
            <MenuItem
              key={o.tcpOffsetOption}
              iconKind={o.iconKind}
              secondaryIconKind={
                o.tcpOffsetOption === tcpOffsetOption ? 'checkmark' : 'blank'
              }
              onClick={() =>
                routineRunnerHandle.setTCPOffsetOption(o.tcpOffsetOption)
              }
            >
              {o.name}
            </MenuItem>
          ))}
        </Menu>
      }
      iconKind={tcpOffsetOptionAndName.iconKind}
      label1="Tooltip"
      label2={tcpOffsetOptionAndName.name}
      isDisabled={controlMode === 'jointControlSingle'}
    />
  );
}
