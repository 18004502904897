import { useCallback, useMemo } from 'react';
import { DoubleSide, MeshStandardMaterial } from 'three';
import { shallow } from 'zustand/shallow';

import type { KinematicState } from '@sb/routine-runner';
import type { OnVisualizerStateChange } from '@sb/visualizer';
import { Robot } from '@sb/visualizer/Robot';
import { VisualizerContextProvider } from '@sb/visualizer/VisualizerContext';

import { useAlohaStore } from '../shared/store';
import type { AlohaState } from '../shared/types';

export function MiniArmVisualizer() {
  const [isConnected, posX, posY, posZ, isGhost] = useAlohaStore(
    (s) =>
      [
        s.isTeleopConnected,
        s.visualizerOptions.x,
        s.visualizerOptions.y,
        s.visualizerOptions.z,
        s.visualizerOptions.isGhost,
      ] as const,
    shallow,
  );

  const onStateChange = useCallback<OnVisualizerStateChange>(
    (kinematicStateSelector, isEqual, onChange) => {
      const alohaStateSelector = (alohaState: AlohaState) => {
        return kinematicStateSelector({
          jointAngles: alohaState.miniArmJointPositions,
          gripperState: null,
        } as KinematicState);
      };

      let tdata = alohaStateSelector(useAlohaStore.getState());

      onChange(tdata);

      return useAlohaStore.subscribe((alohaState) => {
        const newTdata = alohaStateSelector(alohaState);

        if (!isEqual(tdata, newTdata)) {
          tdata = newTdata;
          onChange(tdata);
        }
      });
    },
    [],
  );

  const material = useMemo(() => {
    return new MeshStandardMaterial({
      color: '#222',
      side: DoubleSide,
      roughness: 0.5,
      ...((!isConnected || isGhost) && {
        transparent: true,
        opacity: 0.25,
        depthWrite: false,
      }),
    });
  }, [isConnected, isGhost]);

  return (
    <VisualizerContextProvider
      value={{
        onStateChange,
        axesMode: null,
        material,
      }}
    >
      <group
        scale={isGhost ? 1 : 0.55}
        position={[isGhost ? 0 : posX, isGhost ? 0 : posY, isGhost ? 0 : posZ]}
      >
        <Robot />
      </group>
    </VisualizerContextProvider>
  );
}
