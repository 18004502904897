import { useMemo, useState } from 'react';

import { Icon, NavigationBar, NavigationBarButton } from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';
import { Loader } from '@sb/ui/components';
import { convertDegreesToRadians } from '@sb/utilities';
import WidgetPanel from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetPanel';
import WidgetView from '@sbrc/components/visualizer-view-shared/widget-panel/WidgetView';
import { useDistanceUnitInfo, useRobotTooltipState } from '@sbrc/hooks';
import type { EulerPose } from '@sbrc/utils';
import { convertEulerPose } from '@sbrc/utils';

import { ActiveTCPOffsetOption } from '../tcp-offset/ActiveTCPOffsetOption';

import { JOINT_ANGLE_PRECISION, useMoveRobotViewContext } from './shared';
import { ToolCoordinate } from './ToolCoordinate';
import { ToolCoordinateRotationTypeModal } from './ToolCoordinateRotationTypeModal';
import type { RotationState, RotationUnit } from './types';

interface ToolCoordinateLabel {
  color: 'X' | 'Y' | 'Z';
  label: string;
}

const TOOL_COORDINATE_LABEL_LIST: Record<string, ToolCoordinateLabel> = {
  X: { label: 'X', color: 'X' },
  Rx: { label: 'Roll', color: 'X' },
  Y: { label: 'Y', color: 'Y' },
  Ry: { label: 'Pitch', color: 'Y' },
  Z: { label: 'Z', color: 'Z' },
  Rz: { label: 'Yaw', color: 'Z' },
};

interface UnitData {
  unitLabel: string;
  convertValue: (v: number) => number;
  decimalPlaces: number;
}

const UNIT_DATA: Record<RotationUnit, UnitData> = {
  degrees: {
    unitLabel: 'deg',
    convertValue: (v) => v,
    decimalPlaces: JOINT_ANGLE_PRECISION,
  },
  radians: {
    unitLabel: 'rad',
    convertValue: convertDegreesToRadians,
    decimalPlaces: 3,
  },
};

const POSITION_LABEL_LIST = ['X', 'Y', 'Z'];

const EULER_ANGLE_LABEL_LIST = ['Rx', 'Ry', 'Rz'];

const QUATERNION_LABEL_LIST = ['i', 'j', 'k', 'w'];

const QUATERION_LABEL_DECIMAL_PLACES = 3;

export function MoveRobotToolCoordinates() {
  const distanceUnitInfo = useDistanceUnitInfo();

  const { isVizbot } = useMoveRobotViewContext();

  const [rotation, setRotation] = useState<RotationState>('euler');

  const [rotationUnit, setRotationUnit] = useState<RotationUnit>('degrees');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const tooltipPoint = useRobotTooltipState({ isVizbot });

  /**
   * Routine-runner gives us tool coordinates (pose) as
   * a cartesian point. However, the frontend displays them
   * as Euler.
   */
  const eulerAngles = useMemo(() => {
    return convertEulerPose.fromNullableCartesian(tooltipPoint);
  }, [tooltipPoint]);

  return (
    <>
      <WidgetPanel position="bottom-left">
        <WidgetView>
          <NavigationBar
            className="tw-pl-8 tw-pr-8"
            contentRight={
              <NavigationBarButton
                onClick={() => setIsModalOpen(true)}
                data-testid="move-robot-tool-coordinates-more-trigger-button"
              >
                <Icon kind="ellipsisCircle" />
              </NavigationBarButton>
            }
          >
            Tooltip pose
          </NavigationBar>

          {tooltipPoint === null ? (
            <div className="tw-flex tw-justify-center tw-items-center tw-h-100">
              <Loader />
            </div>
          ) : (
            <div className="tw-grid tw-grid-cols-2 tw-px-16 tw-pt-8 tw-pb-24 tw-gap-28">
              <div className="tw-flex tw-flex-col tw-gap-16">
                {POSITION_LABEL_LIST.map((position) => {
                  const { label, color } = TOOL_COORDINATE_LABEL_LIST[position];

                  const value = eulerAngles[position as keyof EulerPose];

                  return (
                    <ToolCoordinate
                      key={position}
                      coordinateLabel={label}
                      color={color}
                      decimalPlaces={distanceUnitInfo.decimalPlaces}
                      unitLabel={distanceUnitInfo.abbreviation}
                      value={distanceUnitInfo.fromMeters(value)}
                    />
                  );
                })}
              </div>

              <div className="tw-flex tw-flex-col tw-gap-16">
                {rotation === 'euler'
                  ? EULER_ANGLE_LABEL_LIST.map((euler) => {
                      const { label, color } =
                        TOOL_COORDINATE_LABEL_LIST[euler];

                      const value = eulerAngles[euler as keyof EulerPose];

                      const { convertValue, unitLabel, decimalPlaces } =
                        UNIT_DATA[rotationUnit];

                      return (
                        <ToolCoordinate
                          key={euler}
                          coordinateLabel={label}
                          color={color}
                          decimalPlaces={decimalPlaces}
                          unitLabel={unitLabel}
                          value={convertValue(value)}
                        />
                      );
                    })
                  : QUATERNION_LABEL_LIST.map((quaternion) => {
                      return (
                        <ToolCoordinate
                          key={quaternion}
                          coordinateLabel={quaternion}
                          decimalPlaces={QUATERION_LABEL_DECIMAL_PLACES}
                          value={
                            tooltipPoint[quaternion as keyof CartesianPose]
                          }
                        />
                      );
                    })}
              </div>
            </div>
          )}

          <ActiveTCPOffsetOption
            className="tw-mx-16 tw-mb-16 tw-text-center tw-text-15 tw-text-label-tertiary"
            isVizbot={isVizbot}
          />
        </WidgetView>
      </WidgetPanel>

      <ToolCoordinateRotationTypeModal
        rotation={rotation}
        rotationUnit={rotationUnit}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onRotationChange={setRotation}
        onUnitChange={setRotationUnit}
      />
    </>
  );
}
