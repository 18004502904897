import { shallow } from 'zustand/shallow';

import { type IconKind, Menu, MenuItem } from '@sb/design-system';
import {
  FRAMES_OF_REFERENCE,
  type FrameOfReference,
} from '@sb/motion-planning';

import { useMoveRobotViewContext } from '../../shared';

import { JogParamsButton } from './JogParamsButton';
import { useJogParamsStore } from './store';

const FRAME_OF_REFERENCE_INFO: Record<
  FrameOfReference,
  { icon: IconKind; name: string }
> = {
  robotArm: { icon: 'equipmentBase', name: 'Base' },
  tooltip: { icon: 'connectorSevenPinRound', name: 'Tool' },
};

export function FrameOfReferenceSelector() {
  const { controlMode } = useMoveRobotViewContext();

  const [frameOfReference, setJogSettings] = useJogParamsStore(
    (s) => [s.frameOfReference, s.setJogSettings] as const,
    shallow,
  );

  return (
    <JogParamsButton
      menu={
        <Menu>
          {FRAMES_OF_REFERENCE.map((f) => (
            <MenuItem
              key={f}
              iconKind={FRAME_OF_REFERENCE_INFO[f].icon}
              secondaryIconKind={f === frameOfReference ? 'checkmark' : 'blank'}
              onClick={() => setJogSettings({ frameOfReference: f })}
            >
              {FRAME_OF_REFERENCE_INFO[f].name}
            </MenuItem>
          ))}
        </Menu>
      }
      iconKind={FRAME_OF_REFERENCE_INFO[frameOfReference].icon}
      label1="Align"
      label2={FRAME_OF_REFERENCE_INFO[frameOfReference].name}
      isDisabled={controlMode === 'jointControlSingle'}
    />
  );
}
