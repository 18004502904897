import { useCallback } from 'react';

import type { ArmJointPositions } from '@sb/motion-planning';
import { convertJointAngles } from '@sbrc/utils';

import { SpaceWidget } from '../../visualizer-view-shared/space-widget/SpaceWidget';
import { useMoveRobotViewContext } from '../shared';

export default function MoveRobotSpaceWidget() {
  const {
    controlMode,
    isVizbot,
    robot,
    routine,
    targetJointAnglesDegrees,
    setTargetJointAnglesDegrees,
  } = useMoveRobotViewContext();

  const targetKey = isVizbot ? 'vizbot' : 'liveRobot';

  const setTargetJointAngles = useCallback(
    (jointAngles: ArmJointPositions | null) =>
      setTargetJointAnglesDegrees((prevAngles) => {
        return {
          ...prevAngles,
          [targetKey]: jointAngles
            ? convertJointAngles.toDegrees(jointAngles)
            : null,
        };
      }),
    [setTargetJointAnglesDegrees, targetKey],
  );

  return (
    <SpaceWidget
      isActive={controlMode === 'space'}
      routine={routine ?? null}
      robotID={robot.id}
      isVizbot={isVizbot}
      targetJointAngles={convertJointAngles.fromNullableDegrees(
        targetJointAnglesDegrees[targetKey],
      )}
      setTargetJointAngles={setTargetJointAngles}
    />
  );
}
