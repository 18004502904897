import { shallow } from 'zustand/shallow';

import {
  SettingsGroup,
  SettingsGroupSliderItem,
  SettingsGroupSwitchItem,
} from '@sb/design-system';

import { useAlohaStore } from '../shared/store';

export function VisualizerOptions() {
  const [visualizerOptions, setVisualizerOptions] = useAlohaStore(
    (s) => [s.visualizerOptions, s.setVisualizerOptions],
    shallow,
  );

  return (
    <section className="tw-flex tw-flex-col">
      <h5 className="tw-heading-40 tw-pl-16">Visualizer options</h5>

      <SettingsGroup>
        <SettingsGroupSwitchItem
          label="Show as ghost"
          checked={visualizerOptions.isGhost}
          onChange={(e) =>
            setVisualizerOptions({
              ...visualizerOptions,
              isGhost: e.target.checked,
            })
          }
        />

        <SettingsGroupSliderItem
          label="X"
          min={-2}
          max={2}
          step={0.5}
          value={visualizerOptions.x}
          onChange={(e) =>
            setVisualizerOptions({
              ...visualizerOptions,
              x: e.target.valueAsNumber,
            })
          }
          disabled={visualizerOptions.isGhost}
        />

        <SettingsGroupSliderItem
          label="Y"
          min={-2}
          max={2}
          step={0.5}
          value={visualizerOptions.y}
          onChange={(e) =>
            setVisualizerOptions({
              ...visualizerOptions,
              y: e.target.valueAsNumber,
            })
          }
          disabled={visualizerOptions.isGhost}
        />

        <SettingsGroupSliderItem
          label="Z"
          min={-1}
          max={1}
          step={0.25}
          value={visualizerOptions.z}
          onChange={(e) =>
            setVisualizerOptions({
              ...visualizerOptions,
              z: e.target.valueAsNumber,
            })
          }
          disabled={visualizerOptions.isGhost}
        />
      </SettingsGroup>
    </section>
  );
}
