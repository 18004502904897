import { shallow } from 'zustand/shallow';

import { SettingsGroupSwitchItem } from '@sb/design-system';

import { useJogParamsStore } from '../jog-params/store';

export function MotionKindSwitch() {
  const [motionKind, setJogSettings] = useJogParamsStore(
    (s) => [s.motionKind, s.setJogSettings] as const,
    shallow,
  );

  return (
    <SettingsGroupSwitchItem
      label="Move in a straight line"
      checked={motionKind === 'line'}
      onChange={(e) =>
        setJogSettings({
          motionKind: e.target.checked ? 'line' : 'joint',
        })
      }
    />
  );
}
