import { Menu, MenuItem, NavigationBarMenuButton } from '@sb/design-system';

import { useAlohaStore } from '../shared/store';

export function TeleopNavigationBarMenu() {
  const setWidgetMode = useAlohaStore((s) => s.setWidgetMode);

  return (
    <NavigationBarMenuButton
      placement="bottom-start"
      content={
        <Menu>
          <MenuItem iconKind="joint" onClick={() => setWidgetMode('miniArm')}>
            Mini arm
          </MenuItem>
        </Menu>
      }
    />
  );
}
