import type { ComponentColor, IconKind } from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';

export type DegreeOfFreedom = 'x' | 'y' | 'z' | 'roll' | 'pitch' | 'yaw';
export type Direction = '+' | '-';

export interface DofInfo {
  '-': {
    offset: CartesianPose;
    label: string;
    ariaLabel: string;
    iconKind: IconKind;
    innerClassName: string;
    outerClassName: string;
  };
  '+': {
    offset: CartesianPose;
    label: string;
    ariaLabel: string;
    iconKind: IconKind;
    innerClassName: string;
    outerClassName: string;
  };
  // When jogging the tooltip, we present a rotated set of axes such that Y is up and Z is forward
  baseDOF: DegreeOfFreedom;
  buttonColor: ComponentColor;
  iconClassName?: string;
}

// 1 / Sqrt(2)
const { SQRT1_2 } = Math;

export const DOF_INFO: Record<DegreeOfFreedom, DofInfo> = {
  x: {
    '-': {
      offset: { x: -1, y: 0, z: 0, i: 0, j: 0, k: 0, w: 1 },
      label: 'X -',
      ariaLabel: 'x-negative-arrow',
      iconKind: 'arrowDownFill',
      innerClassName: 'tw-flex-col-reverse tw-gap-16',
      outerClassName: 'tw-col-start-3 tw-row-start-6 tw-self-start',
    },
    '+': {
      offset: { x: 1, y: 0, z: 0, i: 0, j: 0, k: 0, w: 1 },
      label: 'X +',
      ariaLabel: 'x-positive-arrow',
      iconKind: 'arrowUpFill',
      innerClassName: 'tw-flex-col tw-gap-16',
      outerClassName: 'tw-col-start-3 tw-row-start-4 tw-self-end',
    },
    baseDOF: 'y',
    buttonColor: 'Red',
  },
  y: {
    '-': {
      offset: { x: 0, y: -1, z: 0, i: 0, j: 0, k: 0, w: 1 },
      label: 'Y -',
      ariaLabel: 'y-negative-arrow',
      iconKind: 'arrowRightFill',
      innerClassName: 'tw-flex-row-reverse tw-gap-20',
      outerClassName: 'tw-col-start-4 tw-row-start-5 tw-justify-self-start',
    },
    '+': {
      offset: { x: 0, y: 1, z: 0, i: 0, j: 0, k: 0, w: 1 },
      label: 'Y +',
      ariaLabel: 'y-positive-arrow',
      iconKind: 'arrowLeftFill',
      innerClassName: 'tw-flex-row tw-gap-20',
      outerClassName: 'tw-col-start-2 tw-row-start-5 tw-justify-self-end',
    },
    baseDOF: 'z',
    buttonColor: 'Green',
  },
  z: {
    '-': {
      offset: { x: 0, y: 0, z: -1, i: 0, j: 0, k: 0, w: 1 },
      label: 'Z -',
      ariaLabel: 'z-negative-arrow',
      iconKind: 'arrowDownFill',
      innerClassName: 'tw-flex-col tw-gap-4 tw-pb-20',
      outerClassName: 'tw-col-start-1 tw-row-start-3 tw-row-end-5',
    },
    '+': {
      offset: { x: 0, y: 0, z: 1, i: 0, j: 0, k: 0, w: 1 },
      label: 'Z +',
      ariaLabel: 'z-positive-arrow',
      iconKind: 'arrowUpFill',
      innerClassName: 'tw-flex-col tw-gap-4 tw-pb-20',
      outerClassName: 'tw-col-start-5 tw-row-start-3 tw-row-end-5',
    },
    baseDOF: 'x',
    buttonColor: 'Blue',
    iconClassName:
      'tw-w-60 tw-h-60 tw-p-12 tw-rounded-full tw-surface-elevated tw-shadow-30 tw-text-[inherit]',
  },
  roll: {
    '-': {
      offset: { x: 0, y: 0, z: 0, i: -SQRT1_2, j: 0, k: 0, w: SQRT1_2 },
      label: 'Roll -',
      ariaLabel: 'Rx-negative-arrow',
      iconKind: 'arrowUturnDownLeftFill',
      innerClassName: 'tw-flex-col tw-gap-4 tw-pb-20',
      outerClassName: 'tw-col-start-1 tw-row-start-1 tw-self-start',
    },
    '+': {
      offset: { x: 0, y: 0, z: 0, i: SQRT1_2, j: 0, k: 0, w: SQRT1_2 },
      label: 'Roll +',
      ariaLabel: 'Rx-positive-arrow',
      iconKind: 'arrowUturnDownRightFill',
      innerClassName: 'tw-flex-col tw-gap-4 tw-pb-20',
      outerClassName: 'tw-col-start-5 tw-row-start-1 tw-self-start',
    },
    baseDOF: 'pitch',
    buttonColor: 'Red',
    iconClassName:
      'tw-w-60 tw-h-60 tw-p-12 tw-rounded-full tw-surface-elevated tw-shadow-30 tw-text-[inherit]',
  },
  pitch: {
    '-': {
      offset: { x: 0, y: 0, z: 0, i: 0, j: -SQRT1_2, k: 0, w: SQRT1_2 },
      label: 'Pitch -',
      ariaLabel: 'Ry-negative-arrow',
      iconKind: 'arrowDownFill',
      innerClassName: 'tw-flex-col-reverse tw-gap-16',
      outerClassName: 'tw-col-start-3 tw-row-start-3 tw-self-start',
    },
    '+': {
      offset: { x: 0, y: 0, z: 0, i: 0, j: SQRT1_2, k: 0, w: SQRT1_2 },
      label: 'Pitch +',
      ariaLabel: 'Ry-positive-arrow',
      iconKind: 'arrowUpFill',
      innerClassName: 'tw-flex-col tw-gap-16',
      outerClassName: 'tw-col-start-3 tw-row-start-1 tw-self-end',
    },
    baseDOF: 'yaw',
    buttonColor: 'Green',
  },
  yaw: {
    '-': {
      offset: { x: 0, y: 0, z: 0, i: 0, j: 0, k: -SQRT1_2, w: SQRT1_2 },
      label: 'Yaw -',
      ariaLabel: 'Rz-negative-arrow',
      iconKind: 'arrowRightFill',
      innerClassName: 'tw-flex-row-reverse tw-gap-20',
      outerClassName: 'tw-col-start-4 tw-row-start-2 tw-justify-self-start',
    },
    '+': {
      offset: { x: 0, y: 0, z: 0, i: 0, j: 0, k: SQRT1_2, w: SQRT1_2 },
      label: 'Yaw +',
      ariaLabel: 'Rz-positive-arrow',
      iconKind: 'arrowLeftFill',
      innerClassName: 'tw-flex-row tw-gap-20',
      outerClassName: 'tw-col-start-2 tw-row-start-2 tw-justify-self-end',
    },
    baseDOF: 'roll',
    buttonColor: 'Blue',
  },
};
