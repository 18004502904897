import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { DesignSystemContextProvider } from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';
import type { DeviceCommand, DynamicBaseState } from '@sb/integrations/device';
import { calculateEndEffectorStateFromCommand } from '@sb/integrations/gripper-general';
import type { ArmJointPositions } from '@sb/motion-planning';
import type { Robot, Routine } from '@sb/remote-control/types';
import type { ArmPosition } from '@sb/routine-runner';
import type { AxesMode } from '@sb/visualizer';
import AppHeader from '@sbrc/components/header/AppHeader';
import {
  useIsRobotBraked,
  useVizbotRoutineRunnerHandle,
  useSyncRoutineEquipmentToVizbot,
} from '@sbrc/hooks';
import { convertJointAngles } from '@sbrc/utils';

import { AppFooter } from '../footer/AppFooter';
import RobotVisualizer from '../robot-visualizer/RobotVisualizer';
import { RobotControlToggle } from '../visualizer-view-shared/RobotControlToggle';
import { useWarningWidget } from '../visualizer-view-shared/warning-widget/useWarningWidget';
import WidgetPanel from '../visualizer-view-shared/widget-panel/WidgetPanel';

import { ControlPanelWidgetView } from './control-panel-widgets/ControlPanelWidgetView';
import {
  useInitializeTargetEulerPose,
  useJogParamsStore,
  useTargetEulerPose,
} from './control-panel-widgets/jog-params/store';
import { ControlModeButtonGroup } from './ControlModeButtonGroup';
import MoveRobotHeaderNav from './MoveRobotHeaderNav';
import MoveRobotLiveStream from './MoveRobotLiveStream';
import { MoveRobotViewPosePopover } from './MoveRobotViewPosePopover';
import type { ControlModeState, TargetJointAnglesState } from './shared';
import {
  MoveRobotViewContext,
  ROBOT_CONTROL_MODE_DEFAULT,
  useGhostRobotState,
} from './shared';

interface InitialState {
  jointAngles?: ArmJointPositions;
  pose?: CartesianPose;
  endEffectorDefaultCommand?: DeviceCommand;
  dynamicBaseState?: DynamicBaseState;
  payloadKg?: number;
}

interface MoveRobotViewProps {
  controlMode?: ControlModeState;
  /**
   * An initial state to load the visualizer with (i.e. when navigating from the move
   * view modal in the step configuration wizard).
   */
  initialState?: InitialState;
  isOnConfirmDisabled?: boolean;
  onConfirmArmPosition?: (armPosition: ArmPosition) => void;
  onConfirmEndEffector?: (
    endEffectorCommand: DeviceCommand,
    payload?: number,
  ) => void;
  onReturn: () => void;
  robot: Robot.ConvertedResponse;
  routine?: Routine.ConvertedResponse | null;
}

const MoveRobotView = ({
  controlMode: currentControlMode,
  initialState,
  isOnConfirmDisabled,
  onConfirmArmPosition,
  onConfirmEndEffector,
  onReturn,
  robot: selectedRobot,
  routine,
}: MoveRobotViewProps) => {
  const robotID = selectedRobot.id;

  const vizbot = useVizbotRoutineRunnerHandle();
  useSyncRoutineEquipmentToVizbot(routine);

  const frameOfReference = useJogParamsStore((s) => s.frameOfReference);

  const [isVizbot, setIsVizbot] = useState<boolean>(false);

  const [isCameraView, setIsCameraView] = useState<boolean>(false);

  const [controlMode, setControlMode] = useState<ControlModeState>(
    currentControlMode ?? ROBOT_CONTROL_MODE_DEFAULT,
  );

  const [targetJointAnglesDegrees, setTargetJointAnglesDegrees] =
    useState<TargetJointAnglesState>({
      liveRobot: initialState?.jointAngles
        ? convertJointAngles.toDegrees(initialState.jointAngles)
        : null,
      vizbot: null,
    });

  const [targetPose] = useTargetEulerPose(isVizbot);

  useInitializeTargetEulerPose(initialState?.pose);

  const isRealRobotBraked = useIsRobotBraked({});

  const [gripperCommand, setGripperCommand] = useState<DeviceCommand | null>(
    null,
  );

  // If user provides an initial state, sync up the visualizer state on mount
  const initialStateToRender = useRef<InitialState | undefined>(initialState);

  useEffect(() => {
    if (!initialStateToRender.current) {
      return;
    }

    const {
      jointAngles,
      endEffectorDefaultCommand,
      payloadKg,
      dynamicBaseState,
    } = initialStateToRender.current;

    if (jointAngles) {
      vizbot.vizbotOnlySetJointPositions(jointAngles);
    }

    if (endEffectorDefaultCommand) {
      vizbot.vizbotOnlySetGripperState(
        calculateEndEffectorStateFromCommand(endEffectorDefaultCommand),
      );
    }

    if (payloadKg) {
      vizbot.setRobotPayload({ mass: payloadKg });
    }

    if (dynamicBaseState) {
      vizbot.vizbotOnlySetDynamicBaseState(dynamicBaseState);
    }

    // clear initialStateToRender so it is only applied once
    initialStateToRender.current = undefined;
  }, [vizbot]);

  const warningWidget = useWarningWidget({
    isVizbot,
    robot: selectedRobot,
    routine,
    isMoveView: true,
  });

  const axesMode = ((): AxesMode => {
    if (warningWidget !== null) {
      return null;
    }

    if (controlMode === 'toolControl') {
      return frameOfReference;
    }

    if (controlMode === 'toolControlTarget') {
      return 'robotArm';
    }

    if (
      controlMode === 'jointControlSingle' ||
      controlMode === 'jointControlDual'
    ) {
      return 'joints';
    }

    return null;
  })();

  const ghostRobotState = useGhostRobotState(
    controlMode,
    isVizbot,
    targetJointAnglesDegrees,
    targetPose,
  );

  useEffect(() => {
    if (isVizbot) {
      setIsCameraView(false);
    }
  }, [isVizbot, vizbot]);

  return (
    <MoveRobotViewContext.Provider
      value={{
        controlMode,
        gripperCommand,
        endEffectorDefaultCommand: initialState?.endEffectorDefaultCommand,
        setGripperCommand,
        isCameraView,
        isVizbot,
        isModal: Boolean(currentControlMode) || Boolean(initialState),
        isRealRobotBraked,
        robot: selectedRobot,
        routine,
        setControlMode,
        setIsCameraView,
        setIsVizbot,
        setTargetJointAnglesDegrees,
        targetJointAnglesDegrees,
      }}
    >
      <DesignSystemContextProvider
        defaultComponentColor={isVizbot ? 'Blue' : 'Orange'}
      >
        <div className={cx('tw-fixed', 'tw-inset-0', 'tw-z-20')}>
          <AppHeader robot={selectedRobot} isVizbot={isVizbot}>
            <MoveRobotHeaderNav
              onReturn={onReturn}
              onConfirmArmPosition={onConfirmArmPosition}
              onConfirmEndEffector={onConfirmEndEffector}
              isOnConfirmDisabled={isOnConfirmDisabled}
            />
          </AppHeader>

          {!isCameraView && (
            <RobotVisualizer
              robotID={robotID}
              isVizbot={isVizbot}
              axesMode={axesMode}
              ghostRobotState={ghostRobotState ?? undefined}
              isFullPage
            />
          )}

          <MoveRobotLiveStream />
          <AppFooter
            left={<MoveRobotViewPosePopover />}
            middle={
              isCameraView ? null : (
                <RobotControlToggle
                  isVizbot={isVizbot}
                  isSwitchableOnRunningRoutine
                  setIsVizbot={setIsVizbot}
                />
              )
            }
            right={
              <ControlModeButtonGroup isDisabled={warningWidget !== null} />
            }
          />

          {warningWidget && (
            <WidgetPanel position="top-right">{warningWidget}</WidgetPanel>
          )}
          <ControlPanelWidgetView isHidden={warningWidget !== null} />
        </div>
      </DesignSystemContextProvider>
    </MoveRobotViewContext.Provider>
  );
};

export default MoveRobotView;
