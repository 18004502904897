import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

import type { DeviceCommand } from '@sb/integrations/device';
import type { ArmJointPositions } from '@sb/motion-planning';
import type { Robot, Routine } from '@sb/remote-control/types';
import type { RoutineRunnerHandle } from '@sbrc/services';
import { convertJointAngles } from '@sbrc/utils';

export type ControlModeState =
  | `equipment-${string}`
  | 'io'
  | 'jointControlSingle'
  | 'jointControlDual'
  | 'space'
  | 'toolControl'
  | 'toolControlTarget'
  | 'toolControlSnapToAxis'
  | 'alohaControl'
  | null;

export type TargetJointAnglesState = {
  liveRobot: ArmJointPositions | null;
  vizbot: ArmJointPositions | null;
};

export const ROBOT_CONTROL_MODE_DEFAULT: ControlModeState = 'toolControl';

export interface MoveRobotViewState {
  controlMode: ControlModeState;
  gripperCommand: DeviceCommand | null;
  endEffectorDefaultCommand?: DeviceCommand;
  setGripperCommand: React.Dispatch<React.SetStateAction<DeviceCommand | null>>;
  isCameraView: boolean;
  isVizbot: boolean;
  isModal?: boolean;
  isRealRobotBraked?: boolean | null;
  robot: Robot.ConvertedResponse;
  routine?: Routine.ConvertedResponse | null;
  setControlMode: Dispatch<SetStateAction<ControlModeState>>;
  setIsCameraView: Dispatch<SetStateAction<boolean>>;
  setIsVizbot: Dispatch<SetStateAction<boolean>>;
  setTargetJointAnglesDegrees: Dispatch<SetStateAction<TargetJointAnglesState>>;
  targetJointAnglesDegrees: TargetJointAnglesState;
}

export const MoveRobotViewContext = createContext<MoveRobotViewState>({
  controlMode: 'toolControl',
  gripperCommand: null,
  setGripperCommand: (
    _command: React.SetStateAction<DeviceCommand | null>,
  ) => {},
  isCameraView: false,
  isVizbot: false,
  isRealRobotBraked: true,
  robot: {} as Robot.ConvertedResponse,
  setControlMode: () => {},
  setIsCameraView: () => {},
  setIsVizbot: () => {},
  setTargetJointAnglesDegrees: () => {},
  targetJointAnglesDegrees: { liveRobot: null, vizbot: null },
});

export const useMoveRobotViewContext = () => useContext(MoveRobotViewContext);

export const getCurrentJointAnglesDegrees = (
  routineRunnerHandle: RoutineRunnerHandle,
): ArmJointPositions | null => {
  const state = routineRunnerHandle.getState();

  return state
    ? convertJointAngles.toDegrees(state.kinematicState.jointAngles)
    : null;
};

export const JOINT_ANGLE_PRECISION = 1;
